import { Component, OnInit, OnDestroy, Input, ViewChild, ElementRef, AfterViewInit } from "@angular/core";
import { getUserLanguage } from "src/app/utils/utils";

@Component({
  selector: "app-info-impressum-component",
  templateUrl: "./info-impressum.component.html",
  styleUrls: ["./info-impressum.component.scss"]
})
export class InfoImpressumComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() uiType: string;
  @Input() languageCode: string;


  @ViewChild("impressumId") newsElement: ElementRef;

  constructor() { }

  public async ngOnInit() {
  }

  public async ngAfterViewInit() {
    // console.log("FooterImpressum:ngAfterViewInit", this.languageCode);
    // this.newsElement.nativeElement.scrollIntoView({ behavior: "smooth" });
  }

  public ngOnDestroy(): void {
  }



}
