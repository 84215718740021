<!-- manage-maps-component -->

<div *ngIf="true">
    <!-- manage-maps-title -->
    <div style="display:flex; padding:0.5rem;">
        <img style="width:35px; height:35px;" src="./assets/icons/arrow-left-thin.svg" alt="symbol"
            title="{{'MAPS.GEN.BACK_ICON_T' | translate}}" (click)="onManageMapsBackClick()">
        <!-- title -->
        <span style="flex-grow:1; flex-shrink:1; flex-basis:content;"></span>
        <!-- <span style="margin-left:0.5rem; font-size:x-large;">{{'MAPS.MAN-MAPS.DLG.TITLE' | translate}}</span> -->
        <h1 style="margin-top:0.3rem; margin-left:0.0rem; margin-bottom:0.0rem; font-size:x-large;">
            {{'MAPS.MAN-MAPS.DLG.TITLE' | translate}}
        </h1>
        <span style="flex-grow:1; flex-shrink:1; flex-basis:content;"></span>
        <!-- change-to-map -->
        <img style="cursor:pointer; margin-right:0.0rem;" width=35px height=35px
            src="./assets/icons/home-map-marker.svg" alt="symbol"
            title="{{'MAPS.GEN.CHANGE-TO-MAP_ICON_T' | translate}}" (click)="onManageMapsBackClick()">
    </div>
    <hr style="margin-top:0.2rem;margin-bottom:0.5rem;">
</div>

<!-- not a test-user -->
<div *ngIf="!isTestUser" style="margin:1.0rem; font-size:large;">
    <ng-container *ngIf="userLanguage==='de'">
        <span style="margin-top:1.0rem; color:red">Diese Funktion zum Verwalten von Karten ist in Arbeit!</span><br>
        <span style="margin-top:1.0rem;">In Kürze wirst du Kartenausschnitte für die Offline-Verwendung herunterladen
            können</span>
    </ng-container>
    <ng-container *ngIf="userLanguage!=='de'">
        <span style="margin-top:1.0rem; color:red">The functions for managing maps are in work!</span><br>
        <span style="margin-top:1.0rem;">Soon, you will be able to download maps for offline usage</span>
    </ng-container>
</div>

<!-- test-user -->
<div *ngIf="isTestUser">

    <!-- stored maps -->
    <div style="padding:0.5rem;">
        <span style="font-size:x-large;">{{'MAPS.MAN-MAPS.DLG.STORED-MAPS_LBL' | translate}}</span><br>
        <span *ngIf="!countStoredMaps">{{'MAPS.MAN-MAPS.DLG.NO-STORED-MAPS_TEXT' | translate}}</span><br>
        <ng-container *ngFor="let map of storedMaps; let i=index">
            <div style="margin-top:0.5rem;font-size:large;">
                <span>{{map.name}}: </span><span>{{map.type}}</span><br>
                <span style="margin-left:1.0rem;">{{map.countMapTiles*0.064 | number:'1.0-1'}} Mb</span>
                <span> (max-zoom = {{map.maxZoom}})</span><br>
                <button style="margin-left:0.5rem;" mat-raised-button color="primary"
                    (click)="onRemoveMapClick(map.name)">{{'MAPS.MAN-MAPS.DLG.REMOVE-MAP_BUTT_TEXT' |
                    translate}}</button>
                <button style="margin-left:0.5rem;" mat-raised-button color="primary"
                    (click)="onZoomToMapClick(map.name)">{{'MAPS.MAN-MAPS.DLG.ZOOM-TO-MAP_BUTT_TEXT' |
                    translate}}</button>
                <div *ngIf="!map.downloadOk" style="margin-left:1.0rem;">
                    <span style="color:red">Download is not finished!</span><br>
                    <button style="margin-left:0.5rem;" mat-raised-button color="primary"
                        (click)="onCompleteDownloadClick(map.name)">{{'MAPS.MAN-MAPS.DLG.RETRY-DOWNLOAD_BUTT_TEXT' |
                        translate}}</button>
                </div>
            </div>
        </ng-container>
    </div>
    <div *ngIf="countStoredMaps>0" style="margin-top:1.0rem; padding:0.5rem;">
        <span>{{countStoredTiles}} {{'MAPS.MAN-MAPS.DLG.TOTAL-TILES_TEXT' | translate}}</span><br>
    </div>

    <!-- switch offline/online-map  -->
    <div *ngIf="countStoredMaps" style="padding:0.5rem;">
        <button *ngIf="!isActiveOfflineMap" mat-raised-button color="primary"
            (click)="onActivateOfflineMapClick()">{{'MAPS.MAN-MAPS.DLG.SWITCH-OFFLINE_BUTT_TEXT' | translate}}</button>
        <button *ngIf="isActiveOfflineMap" mat-raised-button color="primary"
            (click)="onActivateOnlineMapClick()">{{'MAPS.MAN-MAPS.DLG.SWITCH-ONLINE_BUTT_TEXT' | translate}}</button>
    </div>
    <hr style="margin-top:0.2rem;margin-bottom:0.5rem;">

    <!-- download map-extent -->
    <div style="padding:0.5rem;">
        <span style="font-size:x-large;">{{'MAPS.MAN-MAPS.DLG.DOWNLOAD_LBL' | translate}}</span>
        <!-- progress-spinner -->
        <div *ngIf="isDownloadInProgress" style="margin-left:100px;">
            <mat-progress-spinner [mode]="'indeterminate'" [diameter]="50"></mat-progress-spinner>
        </div>

        <!-- map-name -->
        <div *ngIf="!tilesSaved && !isDownloadInProgress && !downloadMapName">
            <button mat-raised-button disabled>{{'MAPS.MAN-MAPS.DLG.DOWNLOAD_BUTT_TEXT' | translate}}</button>
            <div *ngIf="!downloadMapName" style="margin-top:0.5rem;color:red;">{{'MAPS.MAN-MAPS.DLG.DEFINE-NAME_TEXT' |
                translate}}</div>
        </div>
        <div *ngIf="!tilesSaved && !isDownloadInProgress">
            <mat-form-field style="width:300px;">
                <input matInput type="text" spellcheck="false"
                    placeholder="{{'MAPS.MAN-MAPS.DLG.MAP-NAME' | translate}}" [(ngModel)]="downloadMapName" />
            </mat-form-field><br>
        </div>
        <div *ngIf="downloadMapName">
            <button mat-raised-button color="primary"
                (click)="onDownloadMapExtentClick()">{{'MAPS.MAN-MAPS.DLG.DOWNLOAD_BUTT_TEXT' | translate}}</button>
        </div>

        <!-- download-statistics -->
        <div *ngIf="tilesSaved && tilesSaved===tilesToSave && !isDownloadInProgress" style="margin-top:0.5rem;">
            <span style="font-size:large; color:green">{{'MAPS.MAN-MAPS.DLG.DOWNLOAD-FINISHED-OK_TEXT' |
                translate}}</span>
        </div>
        <div *ngIf="tilesSaved<tilesToSave && !isDownloadInProgress" style="margin-top:0.5rem;">
            <span style="font-size:large; color:red">{{'MAPS.MAN-MAPS.DLG.DOWNLOAD-FINISHED-NOK_TEXT' |
                translate}}</span>
        </div>
        <div *ngIf="tilesToSave && !currentMap.downloadOk">
            <span>Download intervall: {{downloadTime}} sec</span>
        </div>
        <div *ngIf="tilesToSave" style="margin-top:0.5rem;">
            <span>{{'MAPS.MAN-MAPS.DLG.TILES-TO-SAVE_TEXT' | translate}}{{tilesToSave}}</span><br>
            <span>{{'MAPS.MAN-MAPS.DLG.TILES-SAVED_TEXT' | translate}}{{tilesSaved}}</span><br>
            <span>{{'MAPS.MAN-MAPS.DLG.TILES-NOT-SAVED_TEXT' | translate}}{{faultCount}}</span><br>
        </div>
    </div>
    <!-- <hr style="margin-top:0.2rem;margin-bottom:0.5rem;"> -->

    <!-- maptype -->
    <div *ngIf="!tilesSaved && !isDownloadInProgress" style="padding:0.5rem;">
        <div style="margin-top:0.5rem;color:red;">{{'MAPS.MAN-MAPS.DLG.SELECT-MAP-TYPE_TEXT' | translate}}</div>
    </div>
    <div style="display:flex; flex-wrap: wrap;">
        <!-- maptiler -->
        <ng-container>
            <div class="map-item" [class.map-item-active]="downloadMapType==='Outdoor'"
                (click)="onSelectMap('Outdoor')">
                <img class="map-image" src="./assets/icons_maps/Maptiler_outdoor_map.png" title="Maptiler outdoor map"
                    alt="map-symbol"><br>
                <div style="text-align:center;">{{'MAPS.SELMAP.DLG.MAP-TYPE-OUTDOOR_TEXT' | translate}}</div>
            </div>
        </ng-container>
        <!-- cyclOSM-map -->
        <ng-container *ngIf="loggedInUser && loggedInUser.id===1">
            <div class="map-item" [class.map-item-active]="downloadMapType==='CycleOSM'"
                (click)="onSelectMap('CycleOSM')">
                <img class="map-image" src="./assets/icons_maps/CyclOSM_map.png" title="CycleOSM" alt="map-symbol"><br>
                <div style="text-align:center;">{{'MAPS.SELMAP.DLG.MAP-TYPE-CYCLOSM_TEXT' | translate}}</div>
            </div>
        </ng-container>
        <!-- google-maps -->
        <ng-container>
            <div class="map-item" [class.map-item-active]="downloadMapType==='Google'" (click)="onSelectMap('Google')">
                <img class="map-image" src="./assets/icons_maps/GoogleMaps_map.png" title="Google map"
                    alt="map-symbol"><br>
                <div style="text-align:center;">{{'MAPS.SELMAP.DLG.MAP-TYPE-GOOGLE-MAPS_TEXT' | translate}}</div>
            </div>
        </ng-container>
        <!-- osm -->
        <ng-container *ngIf="loggedInUser && loggedInUser.id===1">
            <div class="map-item" [class.map-item-active]="downloadMapType==='OSM'" (click)="onSelectMap('OSM')">
                <img class="map-image" src="./assets/icons_maps/OSM_map.png" title="OSM map" alt="map-symbol"><br>
                <div style="text-align:center;">{{'MAPS.SELMAP.DLG.MAP-TYPE-OSM_TEXT' | translate}}</div>
            </div>
        </ng-container>
        <!-- test-map -->
        <ng-container *ngIf="loggedInUser && loggedInUser.id===1 && false">
            <div class="map-item" [class.map-item-active]="downloadMapType==='test-map'"
                (click)="onSelectMap('test-map')">
                <img class="map-image" src="./assets/icons/pin.svg" title="Test-Map" alt="map-symbol"><br>
                <div style="text-align:center;">Test-Map</div>
            </div>
        </ng-container>
    </div>



</div>