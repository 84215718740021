import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { StepperSelectionEvent } from "@angular/cdk/stepper";
import { HttpClient } from "@angular/common/http";
import { WritePropExpr } from "@angular/compiler";
import { Component, OnInit, OnDestroy, AfterViewInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

import Map from "ol/Map";

import { RoPoint } from "src/app/models/geometry";
import { LngLat } from "src/app/models/lnglat";
import { MapPlaceL } from "src/app/models/mapplace";
import { SavedPlaceCategory } from "src/app/models/saved-item-categories";
import { SearchFilter } from "src/app/models/search-parameter";


@Component({
  selector: "app-edit-waypoints-component",
  templateUrl: "./edit-waypoints.component.html",
  styleUrls: ["./edit-waypoints.component.scss"]
})
export class EditWayPointsComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input() uiType: string;
  @Input() wayPoints: MapPlaceL[];
  @Input() isRoundRoute: boolean;
  @Input() backToStart: boolean;
  @Input() currentLocation: LngLat;
  @Input() selectedWayPointIndex: number;      // to select way-point from outside (onMapClick)
  @Input() wayPointPositionOnMap: LngLat;      // clicked and confirmed map position for waypoint
  @Input() savedPlaces: SavedPlaceCategory[];
  @Input() languageCode: string;
  @Input() searchParameter: SearchFilter;
  @Input() foundPlaces: MapPlaceL[];
  @Input() map: Map;
  @Input() sessionId: string;

  @Output() selectedWayPointForMapChange: EventEmitter<number> = new EventEmitter();
  @Output() recalculateRoute: EventEmitter<void> = new EventEmitter();
  @Output() backToStartChange: EventEmitter<boolean> = new EventEmitter();
  @Output() reconvertRouteToUrl: EventEmitter<void> = new EventEmitter();
  @Output() redrawWayPoints: EventEmitter<void> = new EventEmitter();

  // temp-variables
  public wayPointName: string;
  public placeNotFoundError: boolean;
  public foundRefPlaces: MapPlaceL[];

  // status for open dialogs & spinner
  public isSetMapPositionActive: boolean;      // set way-point on map si active
  public isVisibleSearchDialog: boolean;       // show search-dialog
  public isVisibleSavedPlaces: boolean;        // show saved-places
  public isVisibleProgressSpinner: boolean;    // show progress-spinner

  constructor(
    private http: HttpClient,
    // private translate: TranslateService,
  ) {

  }

  public ngOnInit() {
    // console.log("EditWayPoints:ngOnInit-wayPoints", this.wayPoints);
    // console.log("EditWayPoints:ngOnInit-currentDeviceType", this.currentDeviceType);

  }
  public ngAfterViewInit() {
    // console.log("EditWayPoints:ngAfterViewInit");
  }
  public async ngOnChanges(changes: SimpleChanges) {
    // console.log("EditWayPoints:onChanges-changes", changes);

    // waypoints changed
    if (changes.wayPoints) {
      // console.log("EditWayPoints:onChanges-waypoints-changed", this.wayPoints);
    }

    // selectedWayPointIndex changed
    if (changes.selectedWayPointIndex) {
      // console.log("EditWayPoints:onChanges-selectedWayPointIndex-changed", this.selectedWayPointIndex);
      if (!changes.selectedWayPointIndex.firstChange) {
        if (this.selectedWayPointIndex !== -1) {
          this.isSetMapPositionActive = true;
          this.selectedWayPointForMapChange.emit(this.selectedWayPointIndex);
        }
      }
    }

    // wayPointPositionOnMap changed
    if (changes.wayPointPositionOnMap) {
      // console.log("EditWayPoints:onChanges-wayPointPositionOnMap-changed", this.wayPointPositionOnMap);
      if (!changes.wayPointPositionOnMap.firstChange) {
        this.isSetMapPositionActive = false;
        this.isVisibleSearchDialog = false;
        this.selectedWayPointForMapChange.emit(-1);
      }
    }
  }
  public ngOnDestroy() {
    // console.log("EditWayPoints:ngOnDestroy");
  }

  // way-point-selection
  public setSelectedWayPointIndex(index: number) {
    // console.log("EditWayPoints:onSelectionChange-index", index);
    this.selectedWayPointIndex = index;
    const wp = this.wayPoints[index];
    this.wayPointName = wp.name;
  }

  // waypoint-name
  public onWayPointInputClick(index: number) {
    // console.log("EditWayPoints:onWayPointInputClick-index", index);
    this.isSetMapPositionActive = false;
    this.selectedWayPointForMapChange.emit(-1);
    this.setSelectedWayPointIndex(index);
    if (!this.wayPoints[index].name) {
      this.searchParameter.text = this.wayPoints[index].name;
      this.isVisibleSearchDialog = true;
    }
  }
  public onWayPointInputChange(event: any, index: number) {
    // console.log("EditWayPoints:setWayPointInput-index", index);
    this.wayPointName = event.target.value;
  }
  public async onWayPointNameKeyUp(event: KeyboardEvent, index: number) {
    // console.log("EditWayPoints:onWayPointNameKeyUp-event", event);
    this.foundRefPlaces = undefined;
    this.placeNotFoundError = false;
    if (event.key === "Enter") {
      // console.log("EditWayPoints:onWayPointNameKeyUp-wayPointname", this.wayPointName);
      this.wayPoints[index].name = this.wayPointName;
      this.reconvertRouteToUrl.emit();
      return;
    }
    if (event.key === "Escape") {
      return;
    }
  }

  // Drag&Drop
  public onDrop(event: CdkDragDrop<string[]>) {
    // console.log("EditWayPoints:onDrop-event", event);
    moveItemInArray(this.wayPoints, event.previousIndex, event.currentIndex);
    this.selectedWayPointIndex = event.currentIndex;
    this.recalculateRoute.emit();
  }

  // menu-functions -------------------------------------------------------------------------------------------------
  // set to current
  public onSetWayPointToCurrentPositionClick(index: number) {
    // console.log("EditWayPoints:onRouteWayPointCurrentPositionClick-userPosition", this.currentLocation);
    this.wayPoints[index].coordLon = this.currentLocation.lng;
    this.wayPoints[index].coordLat = this.currentLocation.lat;
    let name = "Current location";
    if (this.languageCode === "de") { name = "Aktueller Standort"; }
    this.wayPoints[index].name = name;
    this.wayPoints[index].type = "$current";
    this.selectedWayPointForMapChange.emit(-1);
    this.recalculateRoute.emit();
    this.clearSetMapPositionOnMap();
    this.clearSearch();
    this.clearSavedPlaces();
  }

  // set on map
  public onSetWayPointMapPositionClick(index: number) {
    // console.log("EditWayPoints:onSetWayPointMapPositionClick-index", index;
    this.setSelectedWayPointIndex(index);
    this.selectedWayPointForMapChange.emit(index);
    this.isSetMapPositionActive = true;
    this.clearSearch();
    this.clearSavedPlaces();
  }
  // // define lat/lon
  // public onSetWayPointLatLonClick(index: number) {
  //   this.setSelectedWayPointIndex(index);
  //   this.clearSearch();
  //   this.clearSavedPlaces();
  // }
  // search
  public onSetWayPointSearchClick(index: number) {
    this.isSetMapPositionActive = false;
    this.selectedWayPointForMapChange.emit(-1);
    this.setSelectedWayPointIndex(index);
    this.searchParameter.text = this.wayPoints[index].name;
    this.isVisibleSearchDialog = true;
    // this.clearSetMapPositionOnMap();
    this.clearSavedPlaces();
  }
  public onSelectSearchResult(mapPlace: MapPlaceL) {
    // console.log("EditWayPoints:onSelectSearchResult-mapPlace", mapPlace);
    this.wayPoints[this.selectedWayPointIndex] = mapPlace;
    this.recalculateRoute.emit();
    this.isVisibleSearchDialog = false;
    this.isSetMapPositionActive = false;
    this.selectedWayPointForMapChange.emit(-1);
  }
  public onCreateSearchPointOnMap() {
    // console.log("EditWayPoints:onCreateSearchPointOnMap");
    this.onSetWayPointMapPositionClick(this.selectedWayPointIndex);
  }
  public onSearchBack() {
    // console.log("EditWayPoints:onSearchBack");
    this.isVisibleSearchDialog = false;
  }

  // saved-places
  public onSelectFromSavedPlacesClick(index: number) {
    // console.log("EditWayPoints:onSelectFromSavedPlacesClick");
    this.setSelectedWayPointIndex(index);
    this.isVisibleSavedPlaces = !this.isVisibleSavedPlaces;
    this.clearSetMapPositionOnMap();
    this.clearSearch();
  }

  public onSelectSavedPlace(mapPlace: MapPlaceL) {
    // console.log("EditWayPoints:onSelectSearchResult-mapPlace", mapPlace);
    this.wayPoints[this.selectedWayPointIndex] = mapPlace;
    this.isVisibleSavedPlaces = false;
    this.recalculateRoute.emit();
    this.selectedWayPointForMapChange.emit(-1);
  }

  // remove
  public onRemoveWayPointClick(index: number) {
    // console.log("EditWayPoints:onRouteRemoveWayPointClick-index", index);
    if (this.wayPoints.length <= 2) {
      this.wayPoints[index].coordLon = undefined;
      this.wayPoints[index].coordLat = undefined;
      this.wayPoints[index].name = "";
      this.isSetMapPositionActive = true;
      this.onSetWayPointMapPositionClick(index);
    } else {
      this.wayPoints.splice(index, 1);
      this.isSetMapPositionActive = false;
      this.selectedWayPointForMapChange.emit(-1);
    }
    this.recalculateRoute.emit();
    this.clearSearch();
    this.clearSavedPlaces();
  }

  // add new
  public onAddNewWayPointClick(index: number) {
    // console.log("EditWayPoints:onRouteAddNewWayPointClick-index", index);
    const wp = {} as MapPlaceL;
    wp.type = "waypoint";
    wp.name = "";
    this.wayPoints.splice(index + 1, 0, wp);
    setTimeout(() => {
      this.onSetWayPointMapPositionClick(index + 1);
      this.redrawWayPoints.emit();
    });
    this.clearSearch();
    this.clearSavedPlaces();
  }

  public async onSearchTableWayPointClicked(index: number) {
    // console.log("EditWayPoints:onSearchTableWayPointClicked-index", index);
    this.setSelectedWayPointIndex(index);
    this.isVisibleSearchDialog = true;
  }

  public onBackToStartChange() {
    this.backToStartChange.emit(this.backToStart);
  }

  private clearSavedPlaces() {
    this.isVisibleSavedPlaces = false;
  }
  private clearSetMapPositionOnMap() {
    this.isSetMapPositionActive = false;
  }
  public clearSearch() {
    this.isVisibleSearchDialog = false;
  }

  public canRemoveWayPoint(index: number) {
    if (this.wayPoints.length <= 2) {
      return false;
    }
    return true;
  }

}
