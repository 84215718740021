import { Component, OnInit, OnDestroy, AfterViewInit, Input, EventEmitter, Output, ViewChild, ElementRef } from "@angular/core";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";


@Component({
  selector: "app-maps-infos-component",
  templateUrl: "./maps-infos.component.html",
  styleUrls: ["./maps-infos.component.scss"]
})
export class MapsInfosComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() uiType: string;
  @Input() languageCode: string;

  @Output() close: EventEmitter<void> = new EventEmitter();

  // private paramSubscription: any;
  // private paramSubscriptionRouteParams: any;
  public selectedTabIndex: number;

  public calledFromRouter: boolean;
  public activateFaq: string;

  @ViewChild("infoDlg") infoDlgElement: ElementRef;

  constructor(
    private translate: TranslateService,
    private route: ActivatedRoute,
  ) { }

  public async ngOnInit() {
    console.log("maps-infos:ngOnInit-route", this.route);
    if (!this.languageCode) {
      this.calledFromRouter = true;
      this.languageCode = this.translate.getBrowserLang();
    }
    console.log("maps-infos:ngOnInit-languageCode", this.languageCode);

    // url-parameter
    const snapshotUrl = this.route.snapshot.url;
    if (snapshotUrl.length >= 1) {
      const segment0 = snapshotUrl[0];
      console.log("maps-infos:ngOnInit-segment", segment0);
      if (segment0.path === "infos") { this.selectedTabIndex = 0; }
      if (segment0.path === "about") { this.selectedTabIndex = 0; }
      if (segment0.path === "faq") { this.selectedTabIndex = 1; }
      if (segment0.path === "privacy-policy") { this.selectedTabIndex = 2; }
      if (segment0.path === "terms-of-use") { this.selectedTabIndex = 3; }
      if (segment0.path === "contact") { this.selectedTabIndex = 4; }
      if (segment0.path === "apps") { this.selectedTabIndex = 5; }
    }
    if (snapshotUrl.length >= 2) {
      const segment1 = snapshotUrl[1];
      if (segment1.path === "de") { this.languageCode = "de"; }
      if (segment1.path === "en") { this.languageCode = "en"; }
    }
    if (snapshotUrl.length >= 3) {
      const segment2 = snapshotUrl[2];
      if (segment2.path === "faq-loc") {
        this.activateFaq = segment2.path;
      }
    }
    // set language for i18n
    // this.languageCode = "en";  // for tests
    this.translate.use(this.languageCode);

  }
  public ngAfterViewInit() {
    setTimeout(() => { this.scrollToDlg(); });
  }
  public ngOnDestroy(): void {
  }

  public async onRouteParamsChanged(paramMap: ParamMap) {
    console.log("maps-infos:onRouteParamsChanged-paramMap", paramMap);
  }

  public async onRouteQueryParamsChanged(paramMap: ParamMap) {
    console.log("maps-infos:onRouteQueryParamsChanged-paramMap", paramMap);
  }

  private scrollToDlg() {
    this.infoDlgElement.nativeElement.scrollIntoView({ align: top, behavior: "smooth" });
  }


  public onCloseClick() {
    if (this.calledFromRouter) {
      window.location.href = "/";
    }
    if (!this.calledFromRouter) {
      this.close.emit();
    }
  }

}
