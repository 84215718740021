<!-- maps-admin-component -->

<div>
    <!-- admin-title -->
    <div style="display:flex; padding:0.5rem;">
        <img style="width:30px; height:30px; cursor:pointer;" src="./assets/icons/arrow-left-thin.svg" alt="symbol"
            title="Admin" (click)="onAdminBackClick()">
        <!-- title -->
        <span style="flex-grow:1; flex-shrink:1; flex-basis:content;"></span>
        <span style="margin-left:0.5rem; font-size:x-large;">Admin</span>
        <span style="flex-grow:1; flex-shrink:1; flex-basis:content;"></span>
        <!-- change-to-map -->
        <img style="cursor:pointer; margin-right:0.5rem;" width=36px src="./assets/icons/home-map-marker.svg"
            alt="symbol" title="{{'MAPS.GEN.CHANGE-TO-MAP_ICON_T' | translate}}" (click)="onAdminBackClick()">
    </div>
    <hr style="margin-top:0.5rem;">

    <!-- functions -->
    <div style="margin:0.5rem;">

        <!-- clear notifications in local store -->
        <button class="popup-button" (click)="onRemoveNotificationsClick()">
            <img width="20px" src="./assets/icons/delete.svg">Remove notificatipons
        </button><br>

        <!-- create sitemap for public bike routes -->
        <button style="margin-top:1.0rem;" (click)="onCreateSitemapForBikeRoutesClick()">
            <img width="20px" src="./assets/icons/magnify.svg">Create sitemap for public bike routes
        </button><br>
        <a *ngIf="sitemapNameBikeRoutes" [href]="fileUrlSitemapBikeRoutes" download="{{sitemapNameBikeRoutes}}">
            Download sitemap-file
        </a>

        <!-- create sitemap for public hiking routes -->
        <button style="margin-top:1.0rem;" (click)="onCreateSitemapForHikingRoutesClick()">
            <img width="20px" src="./assets/icons/magnify.svg">Create sitemap for public hiking routes
        </button><br>
        <a *ngIf="sitemapNameHikingRoutes" [href]="fileUrlSitemapHikingRoutes" download="{{sitemapNameHikingRoutes}}">
            Download sitemap-file
        </a>

        <!-- update-db seasons -> months -->
        <br>
        <button style="margin-top:1.0rem;" (click)="onUpdateDbClick()">
            <img width="20px" src="./assets/icons/magnify.svg">Update Db: ???
        </button><br>

    </div>

</div>