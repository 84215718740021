<!-- maps-start-page -->
<div class="dialog-frame">

    <div *ngIf="!hasAutoStart" class="dialog" [class.dialog-s]="uiType=='S'" [class.dialog-xl]="uiType=='L'">

        <!-- app title-->
        <div class="app-title-block" style="flex-grow:0; flex-shrink:0;">
            <img width="96px" height="96px" src="./assets/icons_logo/icon-192x192.png" alt="logo">
            <div class="app-title">
                <div style="margin-top:0.5rem;font-size: x-large;">Trip4You-Maps</div>
                <div style="margin-top:0.5rem; font-size:small;">https://maps.trip4you.net</div>
            </div>
        </div>

        <div style="flex-grow:1; flex-shrink:1;"></div>

        <!-- app-description -->
        <div class="app-description" style="flex-grow:1; flex-shrink:0;">
            <span style="font-size:x-large;">{{'START-PAGE.DESC_TITLE' | translate}}</span><br>
            <div class="app-description-text">
                <span>{{'START-PAGE.DESC_TEXT-L1' | translate}}</span><br>
                <span>{{'START-PAGE.DESC_TEXT-L2' | translate}}</span><br>
                <span>{{'START-PAGE.DESC_TEXT-L3' | translate}}</span><br>
                <ng-container *ngIf="currentDeviceType!=='H-L'">
                    <span style="font-size:medium;">{{'START-PAGE.DESC_TEXT-L4' | translate}}</span><br>
                </ng-container>
            </div>
        </div>

        <div style="flex-grow:1; flex-shrink:1;"></div>

        <!-- start-button -->
        <div class="start-button-block" style="font-size:large; text-align:center; flex-grow:1; flex-shrink:0;">
            <button mat-raised-button color="primary" class="start-button" (click)="onStartAppClick()">
                {{'START-PAGE.START-BUTT_TEXT' | translate}}</button>
        </div>

        <div style="flex-grow:50; flex-shrink:50;"></div>

        <!-- infos -->
        <ng-container *ngIf="true">
            <div class="info-block" style="flex-grow:0; flex-shrink:0;">
                <a class="info-item" href="/about">
                    <span>{{'START-PAGE.ABOUT_TEXT' | translate}}</span></a><br *ngIf="windowWidth<600">
                <a class="info-item" href="/privacy-policy">
                    <span>{{'START-PAGE.PRIVACY-POLICY_TEXT' | translate}}</span></a><span>|</span>
                <a class="info-item" href="/terms-of-use">
                    <span>{{'START-PAGE.TERMS-OF-USE_TEXT' | translate}}</span></a><span>|</span>
                <a class="info-item" href="/contact">
                    <span>{{'START-PAGE.CONTACT_TEXT' | translate}}</span></a>
            </div>
        </ng-container>

    </div>
</div>

<div *ngIf="false" class="dialog-frame">

    <div *ngIf="!hasAutoStart" class="dialog" [class.dialog-s]="uiType=='S'" [class.dialog-xl]="uiType=='L'">

        <!-- app title-->
        <div class="app-title-block">
            <img width="96px" height="96px" src="./assets/icons_logo/icon-192x192.png" alt="logo">
            <div class="app-title">
                <div style="margin-top:0.5rem;font-size: x-large;">Trip4You-Maps</div>
                <div style="margin-top:0.5rem; font-size:small;">https://maps.trip4you.net</div>
            </div>
        </div>

        <!-- app-description -->
        <div class="app-description">
            <span style="font-size:x-large;">{{'START-PAGE.DESC_TITLE' | translate}}</span><br>
            <div class="app-description-text">
                <span>{{'START-PAGE.DESC_TEXT-L1' | translate}}</span><br>
                <span>{{'START-PAGE.DESC_TEXT-L2' | translate}}</span><br>
                <span>{{'START-PAGE.DESC_TEXT-L3' | translate}}</span><br>
                <ng-container *ngIf="currentDeviceType!=='H-L'">
                    <span style="font-size:medium;">{{'START-PAGE.DESC_TEXT-L4' | translate}}</span><br>
                </ng-container>
            </div>
        </div>

        <!-- start-button -->
        <div class="start-button-block" style="font-size:large; text-align:center;">
            <button mat-raised-button color="primary" class="start-button" (click)="onStartAppClick()">
                {{'START-PAGE.START-BUTT_TEXT' | translate}}</button>
        </div>

        <!-- infos -->
        <ng-container *ngIf="currentDeviceType!=='H-L'">
            <div class="info-block">
                <div class="info-item"><a href="/about">
                        <span>{{'START-PAGE.ABOUT_TEXT' | translate}}</span></a>
                </div>
                <div class="info-item"><a href="/privacy-policy">
                        <span>{{'START-PAGE.PRIVACY-POLICY_TEXT' | translate}}</span></a>
                </div>
                <div class="info-item"><a href="/terms-of-use">
                        <span>{{'START-PAGE.TERMS-OF-USE_TEXT' | translate}}</span></a>
                </div>
                <div class="info-item"><a href="/contact">
                        <span>{{'START-PAGE.CONTACT_TEXT' | translate}}</span></a>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="currentDeviceType==='H-L'">
            <div class="info-block">
                <a class="info-item" href="/about">
                    <span>{{'START-PAGE.ABOUT_TEXT' | translate}}</span></a>
                <a class="info-item" href="/privacy-policy">
                    <span>{{'START-PAGE.PRIVACY-POLICY_TEXT' | translate}}</span></a>
                <a class="info-item" href="/terms-of-use">
                    <span>{{'START-PAGE.TERMS-OF-USE_TEXT' | translate}}</span></a>
                <a class="info-item" href="/contact">
                    <span>{{'START-PAGE.CONTACT_TEXT' | translate}}</span></a>
            </div>
        </ng-container>

    </div>
</div>