import { Component, NgZone } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { RockItAuthenticationService } from "../rockit-authentication.service";

@Component({
    templateUrl: "./rockit-social-logout-popup.component.html",
    styleUrls: ["./rockit-social-logout-popup.component.scss"]
})
export class RockitSocialLogoutPopupComponent {
    public isGoogleLoginButtonVisible: boolean;
    public isFacebookLoginButtonVisible: boolean;
    public isDevelopmentLoginButtonVisible: boolean;

    public currentUserName: string;

    // tslint:disable-next-line:max-line-length
    constructor(public dialogRef: MatDialogRef<RockitSocialLogoutPopupComponent>, private authenticationService: RockItAuthenticationService, private zone: NgZone) {
    }

    public async onLogout(): Promise<void> {
        await this.authenticationService.signOut();
        this.zone.run(() => {
            this.dialogRef.close();
        });
    }

    public onCancel() {
        this.dialogRef.close();
    }
}
