import { Component, OnInit, OnDestroy, Input, ElementRef, ViewChild, AfterViewInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { getUserLanguage } from "src/app/utils/utils";
import { environment } from "src/environments/environment";


@Component({
  selector: "page-not-found-component",
  templateUrl: "./page-not-found.component.html",
  styleUrls: ["./page-not-found.component.scss"]
})
export class PageNotFoundComponent implements OnInit, OnDestroy, AfterViewInit {

  public languageCode: string;
  public currentRoute: string;

  constructor(
    private router: Router,
  ) { }

  public apiEndPoint: string = environment.apiEndpoint;

  public async ngOnInit() {
    this.languageCode = getUserLanguage();
    this.languageCode = "en"; // until other versions available
    // console.log("FooterAbout:ngOnInit-languageCode", this.languageCode);

    this.currentRoute = this.apiEndPoint + this.router.routerState.snapshot.url;

  }
  public ngAfterViewInit(): void {
  }

  public ngOnDestroy(): void {
  }



}
