import "hammerjs";
import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";

if (environment.production) {
  enableProdMode();
}

document.addEventListener("DOMContentLoaded", () => {
  platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));
});

// let category = window.location.pathname.slice(1);
// console.log("main.ts:category", category);
const category = "maps";

// meta-description
const snippets = {
  // trips: "Travel planner - Free app for planning individual trips - travel tips",
  // examples: "Examples - Examples for travel planner Trip4You",
  maps: "Maps - Free outdoor app - Route planning, Navigation, Route sharing - no advertising",
  // explore: "Search travel information - free travel travel for trip planning - no advertising",
  // region: "Travel information about " + regionName + " - to plan your trip",
  // place: "Travel information about " + placeName + " - to plan your trip",
};
const snippet = snippets[category];
// console.log("main.ts:snippet", snippet);
const metaDesc = document.querySelector("meta[name='description']");
metaDesc.setAttribute("content", snippet);
// console.log("main.ts:end");
