import { Component, OnInit, OnDestroy, AfterViewInit, Input, EventEmitter, Output, ViewChild, ElementRef } from "@angular/core";
import { localStorageRemoveItem } from "../maps-utils/utils-maps-local-storage";
import { GlobalService } from "src/app/services/global.service";
import { MapSearchRouteFilter } from "src/app/models/search-route-filter";
import { DomSanitizer, SafeValue } from "@angular/platform-browser";


@Component({
  selector: "app-maps-admin-component",
  templateUrl: "./maps-admin.component.html",
  styleUrls: ["./maps-admin.component.scss"]
})
export class MapsAdminComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() uiType: string;
  @Input() languageCode: string;

  @Output() close: EventEmitter<void> = new EventEmitter();

  public sitemapNameBikeRoutes: string;
  public fileUrlSitemapBikeRoutes: SafeValue;
  public sitemapNameHikingRoutes: string;
  public fileUrlSitemapHikingRoutes: SafeValue;

  constructor(
    private globalService: GlobalService,
    private sanitizer: DomSanitizer,
  ) { }

  public async ngOnInit() {
    console.log("Admin:ngOnInit");
  }
  public ngAfterViewInit() {
    setTimeout(() => { this.scrollToDlg(); });
  }
  public ngOnDestroy(): void {
  }

  private scrollToDlg() {
  }

  public onAdminBackClick() {
    this.close.emit();
  }

  public onRemoveNotificationsClick() {
    localStorageRemoveItem("t4y-maps_notifications");
  }


  public async onUpdateDbClick() {
    console.log("Admin:onUpdateDbClick");
    // const ok = await this.globalService.updateDb(1001);
  }

  public async onCreateSitemapForBikeRoutesClick() {
    console.log("Admin:onCreateSitemapForBikeRoutesClick");
    const moveType = "bike";
    this.fileUrlSitemapBikeRoutes = await this.createSitemap(moveType);
    this.sitemapNameBikeRoutes = "sitemap_bike_routes.xml";
  }
  public async onCreateSitemapForHikingRoutesClick() {
    console.log("Admin:onCreateSitemapForHikingRoutesClick");
    const moveType = "hiking";
    this.fileUrlSitemapHikingRoutes = await this.createSitemap(moveType);
    this.sitemapNameHikingRoutes = "sitemap_hiking_routes.xml";
  }

  private async createSitemap(moveType: string) {
    const strNL = "\r\n";
    const strBL2 = "  ";
    const strBL4 = "    ";
    const strBL6 = "      ";
    const searchFilter = {} as MapSearchRouteFilter;
    searchFilter.moveType = moveType;
    searchFilter.textInName = "";
    searchFilter.categories = new Array<string>();
    searchFilter.maxRouteLength = 1000000.0;
    const result = await this.globalService.searchPublicRoutesByFilter(searchFilter, false, 1);
    console.log("Admin:createSiteMap-result", result);
    let data = "";
    data += "<?xml version='1.0' encoding='UTF-8'?>" + strNL;
    data += strNL;
    data += "<urlset xmlns='http://www.sitemaps.org/schemas/sitemap/0.9' xmlns:xhtml='http://www.w3.org/1999/xhtml'>" + strNL;

    data += strNL;
    data += strBL4 + "<!-- " + moveType + " route pages -->" + strNL;
    const routes = result.routes;
    for (const route of routes) {
      if (!route.approved) { continue; }
      // console.log("Admin:createSiteMap-routeName", route.name);
      data += strBL4 + "<url>" + strNL;
      data += strBL4 + "<!-- route:" + route.name + " -->" + strNL;
      data += strBL6 + "<loc>https://maps.trip4you.net/route/de/" + route.routeId + "</loc>" + strNL;
      data += strBL4 + "</url> " + strNL;
    }
    data += strNL;
    data += "</urlset>";
    console.log("Admin:createSiteMap-data", data);
    // download
    const blob = new Blob([data], { type: "application/octet-stream" });
    console.log("Admin:createSiteMap-blob", blob);
    const objUrl = window.URL.createObjectURL(blob);
    console.log("Admin:createSiteMap-objUrl", objUrl);
    const fileUrlSitemap = this.sanitizer.bypassSecurityTrustResourceUrl(objUrl);
    return fileUrlSitemap;
  }
}
