import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit, Input, Output, EventEmitter, ElementRef, SimpleChanges, OnChanges } from "@angular/core";
import { EnumAdminPriv, EnumGlobalStatusCode, EnumRouteType, MapPublicRoute, RefRegion, User } from "src/dto.generated/api";
import { DomSanitizer } from "@angular/platform-browser";

import { GlobalService } from "src/app/services/global.service";
import { getRouteCategoryName, getRouteTypeName, getMonthName } from "../maps-utils/utils-route";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { getAvailableRegions, getAvailableRegionsDevelop } from "../maps-utils/utils-regions";
import { MatSelectChange } from "@angular/material/select";
import { ApiError } from "src/app/models/api-error";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";


@Component({
  selector: "app-publish-route-component",
  templateUrl: "./publish-route.component.html",
  styleUrls: ["./publish-route.component.scss"]
})
export class PublishRouteComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input() uiType: string;
  @Input() languageCode: string;
  @Input() loggedInUser: User;
  @Input() pubRoute: MapPublicRoute;
  @Input() isCurrentRoutePublic: boolean;
  @Input() isCurrentRouteIdPublished: boolean;
  @Input() regions: RefRegion[];

  @Output() close: EventEmitter<string> = new EventEmitter();
  @Output() saveRouteToServer: EventEmitter<string> = new EventEmitter();
  @Output() showError: EventEmitter<ApiError> = new EventEmitter();

  // general variables
  public isTestUser: boolean;
  public isUserRouteAdmin: boolean;
  public canApproveRoute: boolean;

  public difficultyLevel: number;
  public routeType: string;
  public durationHours: number;
  public durationMinutes: number;

  public monthIds: number[];
  public monthNames: string[];
  public monthChecked: boolean[];

  public categoryIds: number[];
  public categoryNames: string[];
  public categoryChecked: boolean[];

  public searchRegionText: string;
  public selectedRegionId: number;
  public selectedRegionIds: number[];
  public selectedRegionNames: string[];
  public foundRefRegions: RefRegion[];


  public routePublishSuccessful: boolean;

  constructor(
    private sanitizer: DomSanitizer,
    private globalService: GlobalService,
  ) { }

  public async ngOnInit() {
    if (this.loggedInUser && this.loggedInUser.id === 1) { this.isTestUser = true; }
    console.log("PublishRoute:ngOnInit-pubRoute", this.pubRoute);

    if (this.loggedInUser) {
      if (this.loggedInUser.mapPriv >= EnumAdminPriv.PrivLevel2) { this.isUserRouteAdmin = true; }
      // console.log("PublishRoute:ngOnInit-isUserRouteAdmin", this.isUserRouteAdmin);
    }

    // region
    this.initRegions();

    this.initRoute();
  }

  private initRoute() {

    // route-type
    this.routeType = getRouteTypeName(this.pubRoute.routeType, this.languageCode);
    // duration
    const duration = Math.floor(this.pubRoute.duration / 60);
    this.durationHours = Math.floor(duration / 60);
    this.durationMinutes = duration - this.durationHours * 60;

    // difficulty-level
    this.difficultyLevel = this.pubRoute.difficultyLevel;

    // months
    this.initMonths();
    if (this.pubRoute.months) {
      const idMonthStrings = this.pubRoute.months.split(",");
      //  console.log("PublishRoute:ngOnInit-idStrings", idStrings);
      for (const idString of idMonthStrings) {
        const id = Number(idString);
        const index = this.monthIds.indexOf(id);
        // console.log("PublishRoute:ngOnInit-index", index);
        this.monthChecked[index] = true;
      }
    }
    // console.log("PublishRoute:ngOnInit-monthNames", this.monthNames);

    // route-categories
    this.initCategories();
    if (this.pubRoute.categories) {
      const idCatStrings = this.pubRoute.categories.split(",");
      //  console.log("PublishRoute:ngOnInit-idStrings", idStrings);
      for (const idString of idCatStrings) {
        const id = Number(idString);
        const index = this.categoryIds.indexOf(id);
        // console.log("PublishRoute:ngOnInit-index", index);
        this.categoryChecked[index] = true;
      }
      // console.log("PublishRoute:ngOnInit-categoryChecked", this.categoryChecked);
    }

    // test, if route can be approved
    this.testIfCanApproveRoute();
  }

  public ngAfterViewInit() {
    // console.log("PublishRoute:ngAfterViewInit");
  }

  public async ngOnChanges(changes: SimpleChanges) {
    console.log("PublishRoute:onChanges-changes", changes);

    // pubRoute changed
    if (changes.pubRoute) {
      console.log("PublishRoute:onChanges-pubRoute-changed", this.pubRoute);
      this.initRoute();
    }

  }

  public ngOnDestroy(): void {
  }

  public onBack() {
    console.log("PublishRoute:ngOnInit-pubRoute", this.pubRoute);
    this.close.emit();
  }
  public onBackToMap() {
    this.close.emit("toMap");
  }

  public onSaveToServerClick() {
    this.saveRouteToServer.emit();
  }

  public onSelectDifficultyLevel(level: number) {
    // console.log("PublishRoute:onSelectDifficultyLevel-level", level);
    this.pubRoute.difficultyLevel = level;
    this.testIfCanApproveRoute();
  }

  public onMonthCheckClicked(event: MatCheckboxChange, index: number) {
    // console.log("PublishRoute:onmonthCheckClicked-index", index);
    this.updateMonths();
    this.testIfCanApproveRoute();
  }

  public onCategoryCheckClicked(event: MatCheckboxChange, index: number) {
    // console.log("PublishRoute:onCategoryCheckClicked-index", index);
    this.updateCategories();
  }

  public onRouteImageIdTextKeyUp(event: KeyboardEvent) {
    // console.log("PublishRoute:onRouteImageIdTextKeyUp", event);
    if (event.key === "Enter" || !event.key) {
      if (!this.pubRoute.imageIds) {
        return;
      }
    }
  }

  public onRouteDescriptionChange() {
    console.log("PublishRoute:onRouteDescriptionChange");
    this.testIfCanApproveRoute();
  }

  // region
  public onRegionSelectionChange(event: MatSelectChange) {
    console.log("PublishRoute:onRegionSelectionChange-event", event);
    this.selectedRegionIds.push(this.selectedRegionId);
    this.selectedRegionNames.push(this.getRegionName(this.selectedRegionId));
    this.updateRegionIds();
    this.testIfCanApproveRoute();
  }
  private getRegionName(id: number) {
    for (const region of this.regions) {
      if (region.id === id) { return region.name; }
    }
  }

  public onRemoveRegionClick(index: number) {
    this.selectedRegionIds.splice(index, 1);
    this.selectedRegionNames.splice(index, 1);
    this.updateRegionIds();
    this.testIfCanApproveRoute();
  }
  public async onSearchRegionTextKeyUp(event: KeyboardEvent) {
    // console.log("PublishRoute:onSearchRegionTextKeyUp", event);
    if (event.key === "Enter" || !event.key) {
      const refRegion = this.findRefRegionInFoundList(this.searchRegionText);
      console.log("PublishRoute:onSearchRegionTextKeyUp-searchRegionText", this.searchRegionText);
      console.log("PublishRoute:onSearchRegionTextKeyUp-refRegion", refRegion);
      if (refRegion) {
        this.selectedRegionIds.push(refRegion.id);
        this.selectedRegionNames.push(refRegion.name);
        this.updateRegionIds();
        this.testIfCanApproveRoute();
        return;
      }
    }
    if (!event.key) {
      // const selectedRefRegion = this.getSelectedRefRegion(this.searchRegionText);
      // console.log("PublishRoute:onSearchRegionTextKeyUp-selectedrefRegion", selectedRefRegion);
      // this.searchRegionText = selectedRefRegion.name;
      // // this.loadDataActive = true;
      // this.selectedRegionIds.push(selectedRefRegion.id);
      // this.selectedRegionNames.push(selectedRefRegion.name);
      // this.updateRegionIds();
      // this.testIfCanApproveRoute();
      return;
    }
    if (this.searchRegionText && this.searchRegionText.length > 2) {
      const result = await this.globalService.searchRefRegionListByName(this.searchRegionText);
      if (result.status !== EnumGlobalStatusCode.Success) {
        const apiErr = this.createApiError(result, "searchRefRegionListByName");
        this.showError.emit(apiErr);
        return;
      }
      this.foundRefRegions = result.refRegions;
    }

  }
  public onFoundRegionSelected(event: MatAutocompleteSelectedEvent) {
    console.log("PublishRoute:onFoundRegionSelected-event", event);
    const selectedRefRegion = this.getSelectedRefRegion(this.searchRegionText);
    console.log("PublishRoute:onFoundRegionSelected-selectedrefRegion", selectedRefRegion);
    this.searchRegionText = selectedRefRegion.name;
    this.selectedRegionId = selectedRefRegion.id;
    setTimeout(() => {
      document.getElementById("regionAutocomplete").blur();
      document.getElementById("regionInput").blur();
    });
    this.selectedRegionIds.push(this.selectedRegionId);
    this.selectedRegionNames.push(this.searchRegionText);
    this.updateRegionIds();
    this.testIfCanApproveRoute();
  }

  private findRefRegionInFoundList(name: string) {
    if (!this.foundRefRegions) { return; }
    return this.foundRefRegions.find(p => p.name.toUpperCase() === name.toUpperCase());
  }
  private getSelectedRefRegion(name: string) {
    for (const xRefRegion of this.foundRefRegions) {
      if (xRefRegion.name === name) {
        return xRefRegion;
      }
    }
  }


  public onUploadRouteClick() {
    // console.log("PublishRoute:onUploadRouteClick");
    this.saveRouteToServer.emit();
    this.close.emit();
  }

  public async onUpdatePublicRouteClick() {
    // console.log("PublishRoute:onUpdatePublicRouteClick");
    const result = await this.globalService.updatePublicRoute(this.pubRoute);
    if (result.status !== EnumGlobalStatusCode.Success) {
      const apiErr = this.createApiError(result, "updatePublicRoute");
      this.showError.emit(apiErr);

    }
    this.close.emit();
  }

  public async onApprovePublicRouteClick() {
    // console.log("PublishRoute:onApprovePublicRouteClick");
    this.pubRoute.approved = true;
    const result = await this.globalService.updatePublicRoute(this.pubRoute);
    if (result.status !== EnumGlobalStatusCode.Success) {
      const apiErr = this.createApiError(result, "updatePublicRoute");
      this.showError.emit(apiErr);
    }
    this.close.emit();
  }

  private async initRegions() {
    this.selectedRegionIds = this.parseNumberArrayOfString(this.pubRoute.regionIds);
    // console.log("PublishRoute:initRegions-selectedRegionIds", this.selectedRegionIds);
    this.selectedRegionNames = new Array<string>();
    for (const regionId of this.selectedRegionIds) {
      let regionName: string;
      regionName = this.getRegionName(regionId);
      if (!regionName) {
        const result = await this.globalService.getRefRegion(regionId, 0, false);
        if (result.status !== EnumGlobalStatusCode.Success) {
          const apiErr = this.createApiError(result, "getRefRegion");
          this.showError.emit(apiErr);
        }
        const refRegion = result.refRegion
        if (refRegion) { regionName = refRegion.name; }
      }
      if (regionName) {
        this.selectedRegionNames.push(regionName);
      }
    }
  }

  private initMonths() {
    this.monthIds = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    this.monthNames = new Array<string>();
    this.monthChecked = new Array<boolean>();
    for (const id of this.monthIds) {
      const name = getMonthName(id, this.languageCode);
      this.monthNames.push(name);
      this.monthChecked.push(false);
    }
  }

  private initCategories() {
    this.categoryIds = [1, 2, 3, 4, 5, 6, 51, 52, 54, 53, 101, 102, 103, 104, 105, 151, 1001];
    this.categoryNames = new Array<string>();
    this.categoryChecked = new Array<boolean>();
    for (const id of this.categoryIds) {
      const name = getRouteCategoryName(id, this.languageCode);
      this.categoryNames.push(name);
      this.categoryChecked.push(false);
    }
  }

  private testIfCanApproveRoute() {
    // console.log("PublishRoute:testIfCanApproveRoute");
    // test, if route can be approved
    this.canApproveRoute = true;
    if (!this.pubRoute.difficultyLevel) { this.canApproveRoute = false; }
    if (!this.pubRoute.months) { this.canApproveRoute = false; }
    if (!this.pubRoute.description) { this.canApproveRoute = false; }
  }

  private updateMonths() {
    // console.log("PublishRoute:updatemonths-categoryChecked", this.categoryChecked);
    let catString = "";
    for (const catId of this.monthIds) {
      const index = this.monthIds.indexOf(catId);
      // console.log("PublishRoute:updatemonths-index", index);
      if (this.monthChecked[index]) {
        if (catString !== "") { catString += ","; }
        catString += catId.toString();
      }
    }
    // console.log("PublishRoute:onCategoryCheckClicked-catString", catString);
    this.pubRoute.months = catString;
  }

  private updateCategories() {
    // console.log("PublishRoute:updateCategories-categoryChecked", this.categoryChecked);
    let catString = "";
    for (const catId of this.categoryIds) {
      const index = this.categoryIds.indexOf(catId);
      // console.log("PublishRoute:updateCategories-index", index);
      if (this.categoryChecked[index]) {
        if (catString !== "") { catString += ","; }
        catString += catId.toString();
      }
    }
    // console.log("PublishRoute:onCategoryCheckClicked-catString", catString);
    this.pubRoute.categories = catString;
    this.testIfCanApproveRoute();
  }

  private updateRegionIds() {
    console.log("PublishRoute:updateRegions-selectedRegionIds", this.selectedRegionIds);
    let regionString = "";
    for (const regionId of this.selectedRegionIds) {
      regionString += "$" + regionId.toString() + "$,";
    }
    this.pubRoute.regionIds = regionString;
  }

  private parseNumberArrayOfString(stringVar: string) {
    const numArray = new Array<number>();
    if (!stringVar) { return numArray; }
    const stringArr = stringVar.split(",");
    for (const stringX of stringArr) {
      if (stringX.length > 2) {
        const stringX2 = stringX.slice(1, stringX.length - 1);
        const numX = Number(stringX2);
        numArray.push(numX);
      }
    }
    return numArray;
  }

  private createApiError(result: any, funcName: string) {
    const apiErr = {} as ApiError;
    apiErr.funcName = funcName;
    apiErr.status = result.status;
    apiErr.errorCode = result.errorCode;
    apiErr.errorMessage = result.errorMessage;
    return apiErr;
  }

}
