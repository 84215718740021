import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import * as uuid from "uuid";

import { RockItCommandSenderService } from "../shell/rockit-command-sender.service";
import { ClientSettingsService } from "./client-settings.service";

import {
  GetLoggedInUserCommand, GetLoggedInUserCommandResult,
  IsApiAvailableCommand, IsApiAvailableCommandResult,
  DoConvertXCommand, DoConvertXCommandResult,

  AddUsageLogMapsCommand, AddUsageLogMapsCommandResult,

  AddPlaceCategoryCommand, AddPlaceCategoryCommandResult,
  GetPlaceCategoriesCommand, GetPlaceCategoriesCommandResult,
  UpdatePlaceCategoryCommand, UpdatePlaceCategoryCommandResult,
  DeletePlaceCategoryCommand, DeletePlaceCategoryCommandResult,

  AddPlaceCommand, AddPlaceCommandResult,
  GetPlacesCommand, GetPlacesCommandResult,
  UpdatePlaceCommand, UpdatePlaceCommandResult,
  DeletePlaceCommand, DeletePlaceCommandResult,

  AddRouteCategoryCommand, AddRouteCategoryCommandResult,
  GetRouteCategoriesCommand, GetRouteCategoriesCommandResult,
  UpdateRouteCategoryCommand, UpdateRouteCategoryCommandResult,
  DeleteRouteCategoryCommand, DeleteRouteCategoryCommandResult,

  AddRouteCommand, AddRouteCommandResult,
  GetRoutesCommand, GetRoutesCommandResult,
  UpdateRouteCommand, UpdateRouteCommandResult,
  DeleteRouteCommand, DeleteRouteCommandResult,

  AddPublicRouteCommand, AddPublicRouteCommandResult,
  UpdatePublicRouteCommand, UpdatePublicRouteCommandResult,
  GetPublicRouteCommand, GetPublicRouteCommandResult,
  GetPublicRouteByRouteIdCommand, GetPublicRouteByRouteIdCommandResult,
  DeletePublicRouteCommand, DeletePublicRouteCommandResult,
  ExistsPublicRouteCommand, ExistsPublicRouteCommandResult,
  SearchPublicRoutesByFilterCommand, SearchPublicRoutesByFilterCommandResult,

  AddTourCategoryCommand, AddTourCategoryCommandResult,
  GetTourCategoriesCommand, GetTourCategoriesCommandResult,
  UpdateTourCategoryCommand, UpdateTourCategoryCommandResult,
  DeleteTourCategoryCommand, DeleteTourCategoryCommandResult,

  AddTourCommand, AddTourCommandResult,
  GetToursCommand, GetToursCommandResult,
  UpdateTourCommand, UpdateTourCommandResult,
  DeleteTourCommand, DeleteTourCommandResult,

  AddLiveTrackingCommand, AddLiveTrackingCommandResult,
  UpdateLiveTrackingCommand, UpdateLiveTrackingCommandResult,
  GetLiveTrackingCommand, GetLiveTrackingCommandResult,

  AddLiveTrackingPositionCommand, AddLiveTrackingPositionCommandResult,
  GetLiveTrackingPositionsCommand, GetLiveTrackingPositionsCommandResult,

  UsageLog, SetNotificationSubscribtionCommand, SetNotificationSubscribtionCommandResult,
  UsageLogMaps, LiveTracking, LiveTrackPosition, MapRoute, MapRouteCategory, MapPublicRoute,

  GetRefRegionCommand, GetRefRegionCommandResult,
  SearchRefRegionListByNameCommand, SearchRefRegionListByNameCommandResult,
  GetRefImageCommand, GetRefImageCommandResult, MapPlaceCategory, MapPlace,
  MapTour,
  MapTourCategory

} from "../../dto.generated/api";
import { MapSearchRouteFilter } from "../models/search-route-filter";



@Injectable()
export class GlobalService {

  public mediaWidth = 0;
  public mediaHeight = 0;
  public sessionId: string;

  constructor(
    private commandSenderService: RockItCommandSenderService,
    private clientSettingsService: ClientSettingsService,
    private http: HttpClient) { }

  // mediaWidth & height
  public setMediaWidth(width: number) {
    this.mediaWidth = width;
  }
  public getMediaWidth() {
    return this.mediaWidth;
  }
  public setMediaHeight(height: number) {
    this.mediaHeight = height;
  }
  public getMediaHeight() {
    return this.mediaHeight;
  }

  // sessionId
  public setSessionId(sessionId: string) {
    this.sessionId = sessionId;
  }
  public getSessionId() {
    // console.log("global-service-sessionId", this.sessionId);
    if (!this.sessionId) { this.sessionId = uuid.v4(); }
    return this.sessionId;
  }

  // user
  public async getLoggedInUser() {
    // console.log("global-service:GetLoggedInUser");
    const result = await this.commandSenderService.sendCommand<GetLoggedInUserCommandResult>(GetLoggedInUserCommand.create({}));
    return result.user;
  }

  // isApiAvailable
  public async isApiAvailable() {
    const result = await this.commandSenderService.sendCommand<IsApiAvailableCommandResult>(IsApiAvailableCommand.create({}));
    return result;
  }

  // admin
  public async updateDb(mode: number) {
    // console.log("global-service:updateDb-mode", mode);
    const result = await this.commandSenderService.sendCommand<DoConvertXCommandResult>
      (DoConvertXCommand.create({ id: mode }));
    return result;
  }

  // usage-log
  // public async addUsageLog(usageLog: UsageLog) {
  //   const result = await this.commandSenderService.sendCommand<AddUsageLogCommandResult>
  //     (AddUsageLogCommand.create({ newUsageLog: usageLog }));
  //   return result.addedUsageLog;
  // }
  public async addUsageLogMaps(usageLog: UsageLogMaps) {
    // console.log("global-service:addUsageLogMaps-usageLog", usageLog);
    const result = await this.commandSenderService.sendCommand<AddUsageLogMapsCommandResult>
      (AddUsageLogMapsCommand.create({ newUsageLog: usageLog }));
    return result;
  }

  // ref-image-base-url
  public getRefImageBaseUrl() {
    return `${this.clientSettingsService.storageConnectionString}travelplannerrefimages/`;
  }

  // places-categories
  public async addPlaceCategory(newPlaceCategory: MapPlaceCategory) {
    // console.log("global-service:addPlaceCategory-newPlaceCategory", newPlaceCategory);
    const result = await this.commandSenderService.sendCommand<AddPlaceCategoryCommandResult>
      (AddPlaceCategoryCommand.create({ newPlaceCategory }));
    return result;
  }
  public async getPlaceCategories() {
    // console.log("global-service:getPlaceCategories");
    const result = await this.commandSenderService.sendCommand<GetPlaceCategoriesCommandResult>
      (GetPlaceCategoriesCommand.create({}));
    return result;
  }
  public async updatePlaceCategory(updatePlaceCategory: MapPlaceCategory) {
    // console.log("global-service:updatePlaceCategory-updatePlaceCategory", updatePlaceCategory);
    const result = await this.commandSenderService.sendCommand<UpdatePlaceCategoryCommandResult>
      (UpdatePlaceCategoryCommand.create({ updatePlaceCategory }));
    return result;
  }
  public async deletePlaceCategory(placeCategoryId: number) {
    // console.log("global-service:deletePlaceCategory-placeCategoryId", placeCategoryId);
    const result = await this.commandSenderService.sendCommand<DeletePlaceCategoryCommandResult>
      (DeletePlaceCategoryCommand.create({ placeCategoryId }));
    return result;
  }

  // saved-places
  public async addPlace(newPlace: MapPlace) {
    // console.log("global-service:addPlace-newPlace", newPlace);
    const result = await this.commandSenderService.sendCommand<AddPlaceCommandResult>
      (AddPlaceCommand.create({ newPlace }));
    return result;
  }
  public async getPlaces() {
    // console.log("global-service:getPlaces");
    const result = await this.commandSenderService.sendCommand<GetPlacesCommandResult>
      (GetPlacesCommand.create({}));
    return result;
  }
  public async updatePlace(updatePlace: MapPlace) {
    // console.log("global-service:updatePlace-updatePlace", updatePlace);
    const result = await this.commandSenderService.sendCommand<UpdatePlaceCommandResult>
      (UpdatePlaceCommand.create({ updatePlace }));
    return result;
  }
  public async deletePlace(placeId: number) {
    // console.log("global-service:deletePlace-placeId", placeId);
    const result = await this.commandSenderService.sendCommand<DeletePlaceCommandResult>
      (DeletePlaceCommand.create({ placeId }));
    return result;
  }

  // route-categories
  public async addRouteCategory(newRouteCategory: MapRouteCategory) {
    // console.log("global-service:addRouteCategory-newRouteCategory", newRouteCategory);
    const result = await this.commandSenderService.sendCommand<AddRouteCategoryCommandResult>
      (AddRouteCategoryCommand.create({ newRouteCategory }));
    return result;
  }
  public async getRouteCategories() {
    // console.log("global-service:getRouteCategories");
    const result = await this.commandSenderService.sendCommand<GetRouteCategoriesCommandResult>
      (GetRouteCategoriesCommand.create({}));
    return result;
  }
  public async updateRouteCategory(updateRouteCategory: MapRouteCategory) {
    // console.log("global-service:updateRouteCategory-updateRouteCategory", updateRouteCategory);
    const result = await this.commandSenderService.sendCommand<UpdateRouteCategoryCommandResult>
      (UpdateRouteCategoryCommand.create({ updateRouteCategory }));
    return result;
  }
  public async deleteRouteCategory(routeCategoryId: number) {
    // console.log("global-service:deleteRouteCategory-routeCategoryId", routeCategoryId);
    const result = await this.commandSenderService.sendCommand<DeleteRouteCategoryCommandResult>
      (DeleteRouteCategoryCommand.create({ routeCategoryId }));
    return result;
  }

  // saved-routes
  public async addRoute(newRoute: MapRoute) {
    // console.log("global-service:addRoute-newRoute", newRoute);
    const result = await this.commandSenderService.sendCommand<AddRouteCommandResult>
      (AddRouteCommand.create({ newRoute }));
    return result;
  }
  public async getRoutes() {
    // console.log("global-service:getRoutes");
    const result = await this.commandSenderService.sendCommand<GetRoutesCommandResult>
      (GetRoutesCommand.create({}));
    return result;
  }
  public async updateRoute(updateRoute: MapRoute) {
    // console.log("global-service:updateRoute-updateRoute", updateRoute);
    const result = await this.commandSenderService.sendCommand<UpdateRouteCommandResult>
      (UpdateRouteCommand.create({ updateRoute }));
    return result;
  }
  public async deleteRoute(routeId: number) {
    // console.log("global-service:deleteRoutes-routeId", routeId);
    const result = await this.commandSenderService.sendCommand<DeleteRouteCommandResult>
      (DeleteRouteCommand.create({ routeId }));
    return result;
  }

  // public-routes
  public async addPublicRoute(newRoute: MapPublicRoute) {
    // console.log("global-service:addPublicRoute-newRoute", newRoute);
    const result = await this.commandSenderService.sendCommand<AddPublicRouteCommandResult>
      (AddPublicRouteCommand.create({ newRoute }));
    return result;
  }
  public async updatePublicRoute(updatePublicRoute: MapPublicRoute) {
    // console.log("global-service:updateRoute-updatePublicRoute", updatePublicRoute);
    const result = await this.commandSenderService.sendCommand<UpdatePublicRouteCommandResult>
      (UpdatePublicRouteCommand.create({ updatePublicRoute }));
    return result;
  }
  public async getPublicRoute(id: number) {
    const result = await this.commandSenderService.sendCommand<GetPublicRouteCommandResult>
      (GetPublicRouteCommand.create({ id }));
    // console.log("global-service:getPublicRoute-route", result.route);
    return result;
  }
  public async getPublicRouteByRouteId(routeId: string) {
    const result = await this.commandSenderService.sendCommand<GetPublicRouteByRouteIdCommandResult>
      (GetPublicRouteByRouteIdCommand.create({ routeId }));
    // console.log("global-service:getPublicRoute-route", result.route);
    return result;
  }
  public async deletePublicRoute(routeId: number) {
    // console.log("global-service:deletePublicRoutes-routeId", routeId);
    const result = await this.commandSenderService.sendCommand<DeletePublicRouteCommandResult>
      (DeletePublicRouteCommand.create({ routeId }));
    return result;
  }
  public async existsPublicRoute(routeId: string) {
    // console.log("global-service:existsPublicRoute-routeId", routeId);
    const result = await this.commandSenderService.sendCommand<ExistsPublicRouteCommandResult>
      (ExistsPublicRouteCommand.create({ routeId }));
    return result;
  }
  public async searchPublicRoutesByFilter(searchFilter: MapSearchRouteFilter, onlyMainData: boolean, approved: number) {
    // console.log("global-service:searchPublicRoutesByFilter-searchFilter", searchFilter);
    const result = await this.commandSenderService.sendCommand<SearchPublicRoutesByFilterCommandResult>
      (SearchPublicRoutesByFilterCommand.create({ searchFilter, onlyMainData, approved }));
    return result;
  }

  // tour-categories
  public async addTourCategory(newTourCategory: MapTourCategory) {
    // console.log("global-service:addTourCategory-newTourCategory", newTourCategory);
    const result = await this.commandSenderService.sendCommand<AddTourCategoryCommandResult>
      (AddTourCategoryCommand.create({ newTourCategory: newTourCategory }));
    return result;
  }
  public async getTourCategories() {
    // console.log("global-service:getTourCategories");
    const result = await this.commandSenderService.sendCommand<GetTourCategoriesCommandResult>
      (GetTourCategoriesCommand.create({}));
    return result;
  }
  public async updateTourCategory(updateTourCategory: MapTourCategory) {
    // console.log("global-service:updateTourCategory-updateTourCategory", updateTourCategory);
    const result = await this.commandSenderService.sendCommand<UpdateTourCategoryCommandResult>
      (UpdateTourCategoryCommand.create({ updateTourCategory }));
    return result;
  }
  public async deleteTourCategory(tourCategoryId: number) {
    // console.log("global-service:deleteTourCategory-tourCategoryId", tourCategoryId);
    const result = await this.commandSenderService.sendCommand<DeleteTourCategoryCommandResult>
      (DeleteTourCategoryCommand.create({ tourCategoryId: tourCategoryId }));
    return result;
  }


  // saved tours
  public async addTour(newTour: MapTour) {
    // console.log("global-service:addTour-newTour", newTour);
    const result = await this.commandSenderService.sendCommand<AddTourCommandResult>
      (AddTourCommand.create({ newTour }));
    return result;
  }
  public async getTours() {
    // console.log("global-service:getTours");
    const result = await this.commandSenderService.sendCommand<GetToursCommandResult>
      (GetToursCommand.create({}));
    return result;
  }
  public async updateTour(updateTour: MapTour) {
    // console.log("global-service:updateTour-updateTour", updateTour);
    const result = await this.commandSenderService.sendCommand<UpdateTourCommandResult>
      (UpdateTourCommand.create({ updateTour }));
    return result;
  }
  public async deleteTour(tourId: number) {
    // console.log("global-service:deleteTour-tourId", tourId);
    const result = await this.commandSenderService.sendCommand<DeleteTourCommandResult>
      (DeleteTourCommand.create({ tourId: tourId }));
    return result;
  }

  // live-tracking
  public async addLiveTracking(liveTracking: LiveTracking) {
    // console.log("global-service:addLiveTracking-liveTracking", liveTracking);
    const result = await this.commandSenderService.sendCommand<AddLiveTrackingCommandResult>
      (AddLiveTrackingCommand.create({ newLiveTracking: liveTracking }));
    return result;
  }
  public async getLiveTracking(trackingId: string) {
    const result = await this.commandSenderService.sendCommand<GetLiveTrackingCommandResult>
      (GetLiveTrackingCommand.create({ trackingId }));
    // console.log("global-service:getLiveTracking-liveTracking", result.liveTracking);
    return result;
  }
  public async updateLiveTracking(liveTracking: LiveTracking) {
    // console.log("global-service:updateLiveTracking-liveTracking", liveTracking);
    const result = await this.commandSenderService.sendCommand<UpdateLiveTrackingCommandResult>
      (UpdateLiveTrackingCommand.create({ updateLiveTracking: liveTracking }));
    return result;
  }

  // live-tracking-positions
  public async addLiveTrackingPosition(liveTrackingPosition: LiveTrackPosition, storeOnlyEndPosition: boolean) {
    const result = await this.commandSenderService.sendCommand<AddLiveTrackingPositionCommandResult>
      (AddLiveTrackingPositionCommand.create({ newLiveTrackPosition: liveTrackingPosition, storeOnlyEndPosition }));
    return result;
  }
  public async getLiveTrackingPositions(trackingId: string, sinceTime: Date) {
    const result = await this.commandSenderService.sendCommand<GetLiveTrackingPositionsCommandResult>
      (GetLiveTrackingPositionsCommand.create({ trackingId, sinceTime }));
    // console.log("global-service:getLiveTracking-liveTrackingPositions", result.liveTrackingPositions);
    return result;
  }

  // notifications
  // public async setNotificationSubscription(subscription: string) {
  //   const result = await this.commandSenderService.sendCommand<SetNotificationSubscribtionCommandResult>
  //     (SetNotificationSubscribtionCommand.create({ subscription }));
  //   return result.saved;
  // }


  // ref-item-service
  public async getRefRegion(refRegionId: number, maxCities: number, includeObjects: boolean) {
    // console.log("GetRefRegion-includeObjects:", includeObjects);
    const result = await this.commandSenderService.sendCommand<GetRefRegionCommandResult>
      (GetRefRegionCommand.create({ refRegionId, maxCities, includeObjects }));
    return result;
  }
  public async searchRefRegionListByName(searchString: string) {
    // console.log("SearchRefRegionListByName-searchString:", searchString);
    const result = await this.commandSenderService.sendCommand<SearchRefRegionListByNameCommandResult>(
      SearchRefRegionListByNameCommand.create({ searchString }));
    return result;
  }

  public async getRefImage(refImageId: number) {
    // console.log("GetRefImage", refImageId);
    const result = await this.commandSenderService.sendCommand<GetRefImageCommandResult>
      (GetRefImageCommand.create({ refImageId }));
    return result.refImage;
  }



}
