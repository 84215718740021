<!-- settings-component -->
<div *ngIf="true">

    <!-- select-map-title -->
    <div style="display:flex; padding:0.5rem;">
        <img style="width:30px; height:30px;" src="./assets/icons/arrow-left-thin.svg" alt="symbol"
            title="{{'MAPS.GEN.BACK_ICON_T' | translate}}" (click)="onSelectMapBackClick()">
        <!-- title -->
        <span style="flex-grow:1; flex-shrink:1; flex-basis:content;"></span>
        <span style="margin-left:0.5rem; font-size:x-large;">{{'MAPS.SELMAP.DLG.TITLE' | translate}}</span>
        <span style="flex-grow:1; flex-shrink:1; flex-basis:content;"></span>
        <!-- change-to-map -->
        <img style="cursor:pointer; margin-right:0.5rem;" width=36px src="./assets/icons/home-map-marker.svg"
            alt="symbol" title="{{'MAPS.GEN.CHANGE-TO-MAP_ICON_T' | translate}}" (click)="onSelectMapBackClick()">
    </div>
    <hr style="margin-top:0.2rem;margin-bottom:0.5rem;">
    <div style="display:flex; flex-wrap: wrap;">
        <!-- base-maps -->
        <!-- osm -->
        <div class="map-item" [class.map-item-active]="mapSource==='OSM'" (click)="onSelectMap('OSM')">
            <img class="map-image" src="./assets/icons_maps/OSM_map.png" title="OSM map" alt="map-symbol"><br>
            <div style="text-align:center;">{{'MAPS.SELMAP.DLG.MAP-TYPE-OSM_TEXT' | translate}}</div>
        </div>
        <!-- maptiler -->
        <div class="map-item" [class.map-item-active]="mapSource==='outdoor'" (click)="onSelectMap('outdoor')">
            <img class="map-image" src="./assets/icons_maps/Maptiler_outdoor_map.png" title="Maptiler outdoor map"
                alt="map-symbol"><br>
            <div style="text-align:center;">{{'MAPS.SELMAP.DLG.MAP-TYPE-OUTDOOR_TEXT' | translate}}</div>
        </div>
        <div class="map-item" [class.map-item-active]="mapSource==='topo'" (click)="onSelectMap('topo')">
            <img class="map-image" src="./assets/icons_maps/Maptiler_topo_map.png" title="Maptiler topo map"
                alt="map-symbol"><br>
            <div style="text-align:center;">{{'MAPS.SELMAP.DLG.MAP-TYPE-TOPO_TEXT' | translate}}</div>
        </div>
        <div class="map-item" [class.map-item-active]="mapSource==='streets'" (click)="onSelectMap('streets')">
            <img class="map-image" src="./assets/icons_maps/Maptiler_streets_map.png" title="Maptiler streets map"
                alt="map-symbol"><br>
            <div style="text-align:center;">{{'MAPS.SELMAP.DLG.MAP-TYPE-STREETS_TEXT' | translate}}</div>
        </div>
        <div class="map-item" [class.map-item-active]="mapSource==='satellite'" (click)="onSelectMap('satellite')">
            <img class="map-image" src="./assets/icons_maps/Maptiler_satellite_map.png" title="Maptiler satellite map"
                alt="map-symbol"><br>
            <div style="text-align:center;">{{'MAPS.SELMAP.DLG.MAP-TYPE-SATELLITE_TEXT' | translate}}</div>
        </div>
        <div class="map-item" [class.map-item-active]="mapSource==='hybrid'" (click)="onSelectMap('hybrid')">
            <img class="map-image" src="./assets/icons_maps/Maptiler_hybrid_map.png" title="Maptiler hybrid map"
                alt="map-symbol"><br>
            <div style="text-align:center;">{{'MAPS.SELMAP.DLG.MAP-TYPE-HYBRID_TEXT' | translate}}</div>
        </div>
        <!-- bing-maps sources -->
        <ng-container *ngIf="loggedInUser && showBingMaps">
            <div class="map-item" [class.map-item-active]="mapSource==='bingmaps-aerial'"
                (click)="onSelectMap('bingmaps-aerial')">
                <img class="map-image" src="./assets/icons_maps/Bingmaps_aerial_map.png" title="Bingmaps Aerial"
                    alt="map-symbol"><br>
                <div style="text-align:center;">{{'MAPS.SELMAP.DLG.MAP-TYPE-BINGMAPS-AERIAL_TEXT' | translate}}</div>
            </div>
            <div class="map-item" [class.map-item-active]="mapSource==='bingmaps-aerial-labels'"
                (click)="onSelectMap('bingmaps-aerial-labels')">
                <img class="map-image" src="./assets/icons_maps/Bingmaps_aerial_labels_map.png" title="Bingmaps Aerial"
                    alt="map-symbol"><br>
                <div style="text-align:center;">{{'MAPS.SELMAP.DLG.MAP-TYPE-BINGMAPS-AERIAL-LABELS_TEXT' | translate}}
                </div>
            </div>
        </ng-container>
        <!-- google-maps -->
        <ng-container>
            <div class="map-item" [class.map-item-active]="mapSource==='googlemaps'"
                (click)="onSelectMap('googlemaps')">
                <img class="map-image" src="./assets/icons_maps/GoogleMaps_map.png" title="Google maps"
                    alt="map-symbol"><br>
                <div style="text-align:center;">{{'MAPS.SELMAP.DLG.MAP-TYPE-GOOGLE-MAPS_TEXT' | translate}}</div>
            </div>
        </ng-container>
        <!-- cyclOSM-map -->
        <ng-container *ngIf="loggedInUser && loggedInUser.id===1 && true">
            <div class="map-item" [class.map-item-active]="mapSource==='cyclosm'" (click)="onSelectMap('cyclosm')">
                <img class="map-image" src="./assets/icons_maps/CyclOSM_map.png" title="CyclOSM" alt="map-symbol"><br>
                <div style="text-align:center;">{{'MAPS.SELMAP.DLG.MAP-TYPE-CYCLOSM_TEXT' | translate}}</div>
            </div>
        </ng-container>
        <!-- no-map -->
        <ng-container>
            <div class="map-item" [class.map-item-active]="mapSource==='no'" (click)="onSelectMap('no')">
                <img class="map-image" src="./assets/icons-new/map-minus.svg" title="No map" alt="map-symbol"><br>
                <div style="text-align:center;">{{'MAPS.SELMAP.DLG.MAP-TYPE-NO-MAP_TEXT' | translate}}</div>
            </div>
        </ng-container>
        <!-- test-map -->
        <ng-container *ngIf="loggedInUser && loggedInUser.id===1 && true">
            <div class="map-item" [class.map-item-active]="mapSource==='test-map'" (click)="onSelectMap('test-map')">
                <img class="map-image" src="./assets/icons/pin.svg" title="Test-Map" alt="map-symbol"><br>
                <div style="text-align:center;">Test-Map</div>
            </div>
        </ng-container>
    </div>

    <!-- overlay-maps -->
    <hr style="margin-top:0.2rem;margin-bottom:0.5rem;">
    <ng-container *ngIf="true">
        <div style="display:flex; flex-wrap: wrap;">
            <!-- waymarked hiking routes -->
            <div class="map-item" [class.map-item-active]="ovlmapSource==='hiking-red'"
                (click)="onOverlayMapHikingRoutesCLick()">
                <img class="map-image" src="./assets/icons_maps/Waymarked_hiking_routes_ovl.png" title="Hiking routes"
                    alt="map-symbol"><br>
                <div style="text-align:center;">
                    <span>{{'MAPS.SELMAP.DLG.MAP-TYPE-WAYMARKER-HIKING_TEXT' | translate}}</span>
                </div>
            </div>
            <!-- waymarked cycling routes -->
            <div class="map-item" [class.map-item-active]="ovlmapSource==='cycling-red'"
                (click)="onOverlayMapCyclingRoutesCLick()">
                <img class="map-image" src="./assets/icons_maps/Waymarked_cycling_routes_ovl.png" title="Cycling routes"
                    alt="map-symbol"><br>
                <div style="text-align:center;">
                    <span>{{'MAPS.SELMAP.DLG.MAP-TYPE-WAYMARKER-CYCLING_TEXT' | translate}}</span>
                </div>
            </div>
            <!-- waymarked MTB routes -->
            <div class="map-item" [class.map-item-active]="ovlmapSource==='mtb-red'"
                (click)="onOverlayMapMtbRoutesCLick()">
                <img class="map-image" src="./assets/icons_maps/Waymarked_mtb_routes_ovl.png"
                    title="Mountainbike routes" alt="map-symbol"><br>
                <div style="text-align:center;">
                    <span>{{'MAPS.SELMAP.DLG.MAP-TYPE-WAYMARKER-MTB_TEXT' | translate}}</span>
                </div>
            </div>
        </div>
        <!-- other overlay-maps -->
        <ng-container *ngIf="loggedInUser && showTestMaps">
        </ng-container>
    </ng-container>

</div>