import { Component, OnInit, OnDestroy, Input, ViewChild, ElementRef, AfterViewInit } from "@angular/core";
import { getUserLanguage } from "src/app/utils/utils";

@Component({
  selector: "app-info-termsofuse-component",
  templateUrl: "./info-termsofuse.component.html",
  styleUrls: ["./info-termsofuse.component.scss"]
})
export class InfoTermsOfUseComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() uiType: string;
  @Input() languageCode: string;

  @ViewChild("termsofuseId") termsofuseElement: ElementRef;

  constructor() { }

  public async ngOnInit() {
    // this.languageCode = "en"; // until other versions available
    // console.log("TermsOfUse:ngOnInit", this.languageCode);
  }

  public async ngAfterViewInit() {
    // console.log("TermsOfUse:ngAfterViewInit", this.languageCode);
    this.termsofuseElement.nativeElement.scrollIntoView({ behavior: "smooth" });
  }

  public ngOnDestroy(): void {
  }



}

