<!-- maps-infos-component -->
<div #infoDlg *ngIf="true">

    <!-- infos-title -->
    <div style="display:flex; padding:0.5rem;">
        <img style="width:30px; height:30px; cursor:pointer;" src="./assets/icons/arrow-left-thin.svg" alt="symbol"
            title="{{'MAPS.GEN.BACK_ICON_T' | translate}}" (click)="onCloseClick()">
        <!-- title -->
        <span style="flex-grow:1; flex-shrink:1; flex-basis:content;"></span>
        <span style="margin-left:0.5rem; font-size:x-large;">{{'MAPS.INFOS.TITLE_TEXT' | translate}}</span>
        <span style="flex-grow:1; flex-shrink:1; flex-basis:content;"></span>
        <!-- change-to-map -->
        <img style="cursor:pointer; margin-right:0.5rem;" width=36px src="./assets/icons/home-map-marker.svg"
            alt="symbol" title="{{'MAPS.GEN.CHANGE-TO-MAP_ICON_T' | translate}}" (click)="onCloseClick()">
    </div>
    <hr style="margin-top:0.2rem;margin-bottom:0.5rem;">

    <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" [selectedIndex]="selectedTabIndex">
        <!-- about -->
        <mat-tab label="{{'MAPS.INFOS.TAB-ABOUT_TITLE' | translate}}">
            <app-info-about-component [languageCode]="languageCode"></app-info-about-component>
        </mat-tab>

        <!-- faq -->
        <mat-tab label="{{'MAPS.INFOS.TAB-FAQ_TITLE' | translate}}">
            <app-info-faq-component [languageCode]="languageCode" [activate]="activateFaq"></app-info-faq-component>
        </mat-tab>

        <!-- privacypolicy -->
        <mat-tab label="{{'MAPS.INFOS.TAB-PRIVACY-POLICY_TITLE' | translate}}">
            <app-info-privacypolicy-component [languageCode]="languageCode"></app-info-privacypolicy-component>
        </mat-tab>

        <!-- termsofuse -->
        <mat-tab label="{{'MAPS.INFOS.TAB-TERMS-OF-USE_TITLE' | translate}}">
            <app-info-termsofuse-component [languageCode]="languageCode"></app-info-termsofuse-component>
        </mat-tab>

        <!-- contact -->
        <mat-tab label="{{'MAPS.INFOS.TAB-CONTACT_TITLE' | translate}}">
            <app-info-impressum-component [languageCode]="languageCode"></app-info-impressum-component>
        </mat-tab>

        <!-- other apps -->
        <mat-tab label="{{'MAPS.INFOS.TAB-MORE-APPS_TITLE' | translate}}">
            <app-info-other-apps-component [languageCode]="languageCode"></app-info-other-apps-component>
        </mat-tab>

    </mat-tab-group>


</div>