import { Component, OnInit, OnDestroy, AfterViewInit, Input, EventEmitter, Output } from "@angular/core";
import { getUserLanguage } from "src/app/utils/utils";


@Component({
  selector: "app-info-other-apps-component",
  templateUrl: "./info-other-apps.component.html",
  styleUrls: ["./info-other-apps.component.scss"]
})
export class InfoOtherAppsComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() uiType: string;
  @Input() languageCode: string;

  @Output() close: EventEmitter<void> = new EventEmitter();


  constructor() { }

  public async ngOnInit() {
    // console.log("InfoOtherApps:ngOnInit");
    // this.languageCode = getUserLanguage();
    // this.languageCode = "en"; // until other versions available
  }
  public ngAfterViewInit() {
  }
  public ngOnDestroy(): void {
  }

}
