import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit, Input, Output, EventEmitter, ElementRef } from "@angular/core";
import { EnumRouteType, MapPublicRoute, RefImage, User } from "src/dto.generated/api";
import { DomSanitizer } from "@angular/platform-browser";
import { MatDialog } from "@angular/material/dialog";

import { GlobalService } from "src/app/services/global.service";
import { GeneralPopupComponent } from "src/app/global-components/popup-general/popup-general.component";
import { getDifficultyLevelText, getRouteCategoryName, getRouteTypeName, getMonthName, getRouteDurationCorrectionFactor } from "../maps-utils/utils-route";
import { MapPlaceL } from "src/app/models/mapplace";
// import { Color } from "openlayers";
import { getColorFromIndex } from "../maps-utils/utils-route-categories";


@Component({
  selector: "app-present-route-component",
  templateUrl: "./present-route.component.html",
  styleUrls: ["./present-route.component.scss"]
})
export class PresentRouteComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() uiType: string;
  @Input() languageCode: string;
  @Input() loggedInUser: User;
  @Input() route: MapPublicRoute;
  @Input() routeIndex: number;
  @Input() open: boolean;

  @Output() drawPublicRoute: EventEmitter<MapPublicRoute> = new EventEmitter();
  @Output() activatePublicRoute: EventEmitter<MapPublicRoute> = new EventEmitter();
  @Output() removePublicRoute: EventEmitter<MapPublicRoute> = new EventEmitter();

  // general variables
  public isTestUser: boolean;

  public moveType: string;
  public difficultyLevel: string;
  public routeType: string;
  public durationHours: number;
  public durationMinutes: number;
  public moreData: boolean;
  public difficultiLevelText: string;
  public months: string[];
  public categories: string[];
  public pois: MapPlaceL[];
  public routeImage: RefImage;
  public refImageBaseUrl: string;
  public indexColor: string;

  constructor(
    private sanitizer: DomSanitizer,
    private globalService: GlobalService,
    public dialog: MatDialog,
  ) { }

  public async ngOnInit() {
    if (this.loggedInUser && this.loggedInUser.id === 1) { this.isTestUser = true; }

    this.refImageBaseUrl = this.globalService.getRefImageBaseUrl();
    this.indexColor = getColorFromIndex(this.routeIndex);


    // difficulty
    // if(this.route.difficultyLevel === EnumDifficultyLevel.)

    // moveType
    let corrFactor = 1.0;
    if (this.route.plannerOptions) {
      const plannerOptions = JSON.parse(this.route.plannerOptions);
      // console.log("PresentRoute:ngOnInit-plannerOptions", plannerOptions);
      this.moveType = plannerOptions.moveType;
      // console.log("PresentRoute:ngOnInit-moveType", this.moveType);
    }
    // route-type
    this.routeType = getRouteTypeName(this.route.routeType, this.languageCode);
    // duration
    let duration = this.route.duration;
    // correction
    corrFactor = getRouteDurationCorrectionFactor(this.moveType);
    // console.log("PresentRoute:ngOnInit-corrFactor", corrFactor);
    duration = Math.floor(duration * corrFactor / 60);
    this.durationHours = Math.floor(duration / 60);
    this.durationMinutes = duration - this.durationHours * 60;

    // difficulty-level
    this.difficultiLevelText = getDifficultyLevelText(this.route.difficultyLevel, this.languageCode);

    // regions

    // months
    this.months = new Array<string>();
    if (this.route.months) {
      const catIds = this.route.months.split(",");
      for (const catIdString of catIds) {
        const id = Number(catIdString);
        const catName = getMonthName(id, this.languageCode);
        this.months.push(catName);
      }
    }

    // categories
    this.categories = new Array<string>();
    if (this.route.categories) {
      const catIds = this.route.categories.split(",");
      for (const catIdString of catIds) {
        const id = Number(catIdString);
        const catName = getRouteCategoryName(id, this.languageCode);
        this.categories.push(catName);
      }
    }

    // pois
    if (this.route.pois) {
      this.pois = JSON.parse(this.route.pois);
      // console.log("PresentRoute:ngOnInit-pois", this.pois);
    }

    // route-image
    if (this.route.imageIds) {
      const imageId = Number(this.route.imageIds);
      const result = await this.globalService.getRefImage(imageId);
      // if(!result) {
      //   return;
      // }
      this.routeImage = result;
      if (!this.routeImage.width) {
        this.routeImage.width = 4;
        this.routeImage.height = 3;
      }
    }

    // open
    if (this.open) { this.moreData = true; }
  }

  public ngAfterViewInit() {
    // console.log("PresentRoute:ngAfterViewInit");
  }
  public ngOnDestroy(): void {
  }

  public onRouteClick() {
    this.moreData = !this.moreData;
  }

  public onRemoveRouteClick() {
    // console.log("PresentRoute:onRemoveRouteClick-route", this.route);
    this.RemovePublicRoutePopup(this.route, this.languageCode);
  }
  private RemovePublicRoutePopup(route: MapPublicRoute, language: string) {
    // console.log("Maps:removePublicRoutePopup-route", route);
    const title = "Öffentliche Route löschen";
    const dialogRef = this.dialog.open(GeneralPopupComponent, {
      closeOnNavigation: true,
      data: {
        language,
        dlgTitle: title,
        dlgText: "'" + route.name + "' " + "wirklich aus Datenbank löschen?",
        buttonYes: true,
        buttonNo: true,
      },
    });
    dialogRef.afterClosed().subscribe(popupResult => {
      // console.log("Maps:removePublicRoutePopup-popupResult", popupResult);
      if (popupResult === "yes") { this.onRemoveRoute(); }
    });
  }
  public onRemoveRoute() {
    // console.log("PresentRoute:onRemoveRoute-route", this.route);
    this.removePublicRoute.emit(this.route);
  }

  public onActivateRoute() {
    // console.log("PresentRoute:onActivateRoute-route", this.route);
    this.activatePublicRoute.emit(this.route);
  }

  public onDrawRoute() {
    // console.log("PresentRoute:onActivateRoute-route", this.route);
    this.drawPublicRoute.emit(this.route);
  }

  public onImageClick(refImage: RefImage) {

  }


}
