import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit, Input, Output, EventEmitter, ElementRef } from "@angular/core";
import { Size } from "ol/size";
import VectorTileSource from "ol/source/VectorTile";
import { extend as Extend, createEmpty } from "ol/extent.js";
import { toLonLat, fromLonLat, transform } from "ol/proj";
import Map from "ol/Map";
import Layer from "ol/layer/Layer";
import Feature from "ol/Feature";
import Polygon from "ol/geom/Polygon";

import { DbTile } from "src/app/models/db-tiles";
import { OfflineMap } from "src/app/models/offline-maps";
import { getCycleOsmMapSource, getGoogleMapsSource, getMaptilerOutdoorSource, getOsmMapSource } from "src/app/utils/utils-ol-map";
import { User } from "src/dto.generated/api";

@Component({
  selector: "app-manage-maps-component",
  templateUrl: "./manage-maps.component.html",
  styleUrls: ["./manage-maps.component.scss"]
})
export class ManageMapsComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() uiType: string;
  @Input() loggedInUser: User;
  @Input() userLanguage: string;
  @Input() isActiveOfflineMap: boolean;
  @Input() indexedDb: IDBDatabase;
  @Input() serverMapSource: VectorTileSource;
  @Input() map: Map;
  @Input() mapExtent: Extend;

  @Output() back: EventEmitter<void> = new EventEmitter();
  @Output() zoomToMapExtent: EventEmitter<Extend> = new EventEmitter();
  @Output() activateOnlineMap: EventEmitter<void> = new EventEmitter();
  @Output() activateOfflineMap: EventEmitter<void> = new EventEmitter();

  public isTestUser: boolean;

  public downloadMapType: string;
  public downloadMapSource: VectorTileSource;
  public downloadMapName: string;
  public isDownloadInProgress: boolean;

  // indexedDb
  public countStoredMaps: number;
  public storedMaps: OfflineMap[];
  public countStoredTiles: number;

  // download map-tiles
  public currentMap: OfflineMap;
  public tilesToSave: number;
  public tilesSaved: number;
  public faultCount: number;

  // map-extent-source
  public mapExtentSource: any;

  // success
  public lastSuccess: Date;
  public downloadTime: number;

  constructor() { }

  public async ngOnInit() {
    // user-rights
    if (this.loggedInUser && this.loggedInUser.id === 1) {
      this.isTestUser = true;
    }
    // if (this.loggedInUser && this.loggedInUser.id === 15) {
    //   this.isTestUser = true;
    // }

    // map-extent-source
    const layerCollection = this.map.getLayers();
    const layers = layerCollection.getArray();
    // console.log("ManageMaps:ngOnInit-layers", layers);
    for (const layer of layers) {
      const olLayer = layer as Layer;
      const name = olLayer.get("name");
      if (name === "map-extents") {
        // console.log("ManageMaps:ngOnInit-getSource", olLayer.getSource());
        this.mapExtentSource = olLayer.getSource();
      }
    }

    // read maps from indexDb
    if (this.indexedDb) {
      await this.requestMapsFromIndexDb();
      await this.requestCountTilesFromIndexDb();
    }

    // download-map
    this.downloadMapType = "Outdoor";
    if (this.countStoredMaps > 0) {
      const indexLastMap = this.storedMaps.length - 1;
      this.downloadMapType = this.storedMaps[indexLastMap].type;
    }
    this.selectMap(this.downloadMapType);

  }
  public ngAfterViewInit() {
    // console.log("ManageMaps:ngAfterViewInit");
  }
  public ngOnDestroy(): void {
  }

  public onManageMapsBackClick() {
    this.back.emit();
  }

  public onActivateOnlineMapClick() {
    // console.log("ManageMaps:onActivateOnlineMapClick-isActiveOfflineMap", this.isActiveOfflineMap);
    this.mapExtentSource.clear();
    this.activateOnlineMap.emit();
  }
  public onActivateOfflineMapClick() {
    this.redrawStoredMapExtents();
    this.activateOfflineMap.emit();
  }

  public onSelectMap(mapType: string) {
    // console.log("ManageMaps:onSelectMap-mapType", mapType);
    this.selectMap(mapType);
  }
  private selectMap(mapType: string) {
    // console.log("ManageMaps:onSelectMap-mapType", mapType);
    this.downloadMapType = mapType;
    if (mapType === "OSM") this.downloadMapSource = getOsmMapSource();
    if (mapType === "Outdoor") this.downloadMapSource = getMaptilerOutdoorSource();
    if (mapType === "Google") this.downloadMapSource = getGoogleMapsSource();
    if (mapType === "CycleOSM") this.downloadMapSource = getCycleOsmMapSource();
    if (mapType === "test-map") this.downloadMapSource = this.getTestMapSource();
  }

  public getTestMapSource() {
    console.log("ManageMaps:setTestMapSource");

    // const tileSource = new XYZ({
    //   url: "http://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}",
    //   attributions: "<span>© Google Maps</span>",
    //   maxZoom: 19
    // });
    // return tileSource;
    return undefined;
  }

  public onDownloadMapExtentClick() {
    // console.log("ManageMaps:onDownloadMapExtentClick-mapName", this.mapName);
    this.downloadMapToIndexedDb();
  }

  public requestMapsFromIndexDb() {
    const transactionMaps = this.indexedDb.transaction(["Maps"], "readonly");
    // console.log("Maps:openIndexedDb-onsuccess-transactionMaps", transactionMaps);
    const objectStoreMaps = transactionMaps.objectStore("Maps");
    const countMapsRequest = objectStoreMaps.count();
    countMapsRequest.onsuccess = (event1) => {
      // console.log("Maps:openIndexedDb-onsuccess-countMapsRequest.result", countMapsRequest.result);
      this.countStoredMaps = countMapsRequest.result;
    };
    this.storedMaps = new Array<OfflineMap>();
    const cursorRequest = objectStoreMaps.openCursor();
    // console.log("Maps:requestIndexedDbCountItems-cursorRequest", cursorRequest);
    cursorRequest.onsuccess = (event1) => {
      const cursor = cursorRequest.result;
      if (cursor) {
        // console.log("Maps:requestIndexedDbCountItems-cursor", cursor);
        const newMap = JSON.parse(cursor.value.data);
        // console.log("ManageMaps:requestMapsFromIndexDb-newMap", newMap);
        this.storedMaps.push(newMap);
        cursor.continue();
      }
    };
  }
  public requestCountTilesFromIndexDb() {
    const transactionTiles = this.indexedDb.transaction(["MapTiles"], "readonly");
    // console.log("Maps:openIndexedDb-onsuccess-transactionTiles", transactionTiles);
    const objectStoreTiles = transactionTiles.objectStore("MapTiles");
    const countTilesRequest = objectStoreTiles.count();
    // console.log("Maps:openIndexedDb-onsuccess-countTilesRequest", countTilesRequest);
    countTilesRequest.onsuccess = (event) => {
      // console.log("Maps:openIndexedDb-onsuccess-countTilesRequest.result", countTilesRequest.result);
      this.countStoredTiles = countTilesRequest.result;
      if (this.countStoredTiles > 0) { this.isTestUser = true; }
    };
  }

  public async downloadMapToIndexedDb() {
    // console.log("ManageMaps:downloadMapIndexedDb-mapRef", this.mapRef);
    this.currentMap = undefined;
    this.isDownloadInProgress = true;
    const mapType = this.downloadMapType;
    const source = this.downloadMapSource;
    // console.log("ManageMaps:downloadMapIndexedDb-source", source);
    const view = this.map.getView();
    // console.log("ManageMaps:downloadMapIndexedDb-view", view);
    let extent = this.mapExtent;
    console.log("ManageMaps:downloadMapIndexedDb-extent1", extent);
    if (!isFinite(extent[0])) { extent = view.calculateExtent(this.map.getSize()); }
    console.log("ManageMaps:downloadMapIndexedDb-extent2", extent);
    const coordLL = toLonLat([extent[0], extent[1]]);
    // console.log("ManageMaps:downloadMapIndexedDb-coordLL", coordLL);
    const coordUR = toLonLat([extent[2], extent[3]]);
    // console.log("ManageMaps:downloadMapIndexedDb-coordUR", coordUR);
    this.tilesToSave = 0;
    this.tilesSaved = 0;
    this.faultCount = 0;
    // tile-array
    const tileCoordArray = new Array();
    let currentZoom = view.getZoom();
    // console.log("ManageMaps:downloadMapIndexedDb-currentZoom", currentZoom);
    currentZoom = Math.round(currentZoom);
    // const minZoom = 3;
    let minZoom = currentZoom - 1;
    if (minZoom < 3) { minZoom = 3; }
    let maxZoom = currentZoom + 4;
    if (maxZoom > 18) { maxZoom = 18; }
    // calculate corrected maxZoom
    const maxTiles = 2000;
    // const maxTiles = 500;
    let maxZoomCorr = minZoom;
    let toManyTiles = false;
    for (let zoom = minZoom; zoom <= maxZoom; zoom++) {
      // console.log("ManageMaps:downloadMapIndexedDb-zoom", zoom);
      // console.log("ManageMaps:downloadMapIndexedDb-tilesToSave", this.tilesToSave);
      if (toManyTiles) { break; }
      source.getTileGrid().forEachTileCoord(extent, zoom, (tileCoord) => {
        if (this.tilesToSave < maxTiles) {
          this.tilesToSave++;
        } else { toManyTiles = true; }
      });
      if (!toManyTiles) { maxZoomCorr = zoom; }
    }
    console.log("ManageMaps:downloadMapIndexedDb-maxZoomCorr", maxZoomCorr);
    // console.log("ManageMaps:downloadMapIndexedDb-tilesToSave2", this.tilesToSave);

    // loop for download timeout
    this.lastSuccess = new Date(Date.now());
    const maxIntervall = 120;
    const intervalId = setInterval(() => {
      const now = new Date(Date.now());
      this.downloadTime = now.getTime() - this.lastSuccess.getTime();
      this.downloadTime = Math.round(this.downloadTime / 1000);
      if (this.downloadTime > maxIntervall) {
        this.isDownloadInProgress = false;
        this.faultCount = this.tilesToSave - this.tilesSaved;
        clearInterval(intervalId);
      }
    }, 1000);
    this.tilesToSave = 0;
    for (let zoom = minZoom; zoom <= maxZoomCorr; zoom++) {
      // console.log("ManageMaps:downloadMapIndexedDb-zoom1", zoom);
      source.getTileGrid().forEachTileCoord(extent, zoom, (tileCoord) => {
        // console.log("ManageMaps:downloadMapIndexedDb-tileCoord", tileCoord);
        tileCoordArray.push(tileCoord);
        this.downloadTile(source, tileCoord);
      });
    }
    // store map to indexedDb
    console.log("ManageMaps:downloadMapIndexedDb-storeMap:");
    const newMap = {} as OfflineMap;
    newMap.name = this.downloadMapName;
    newMap.type = mapType;
    newMap.countMapTiles = tileCoordArray.length;
    newMap.mapTiles = tileCoordArray;
    newMap.maxZoom = maxZoomCorr;
    const mapExtent = this.calculateMapExtent(newMap);
    newMap.mapExtent = mapExtent;
    this.storedMaps.push(newMap);
    this.currentMap = newMap;
    await this.storeMap(newMap);
    this.countStoredMaps++;
    // clear mapName
    this.downloadMapName = undefined;
    // console.log("------------------------ManageMaps:downloadMapIndexedDb-end");
  }


  public onCompleteDownloadClick(mapKey: string) {
    const index = this.getIndexOfStoredMap(mapKey);
    this.currentMap = this.storedMaps[index];
    console.log("ManageMaps:onCompleteDownloadClick-currentMap:", this.currentMap);
    // this.downloadMapName = this.currentMap.name;
    this.tilesToSave = 0;
    this.tilesSaved = 0;
    this.faultCount = 0;
    // loop for download timeout
    this.lastSuccess = new Date(Date.now());
    const maxIntervall = 120;
    const intervalId = setInterval(() => {
      const now = new Date(Date.now());
      this.downloadTime = now.getTime() - this.lastSuccess.getTime();
      this.downloadTime = Math.round(this.downloadTime / 1000);
      if (this.downloadTime > maxIntervall) {
        this.isDownloadInProgress = false;
        this.faultCount = this.tilesToSave - this.tilesSaved;
        clearInterval(intervalId);
      }
    }, 1000);

    const mapType = this.currentMap.type;
    this.selectMap(mapType);
    const source = this.downloadMapSource;
    this.isDownloadInProgress = true;

    const tileCoordsArray = this.currentMap.mapTiles;
    const transactionTiles = this.indexedDb.transaction(["MapTiles"], "readwrite");
    const objectStoreTiles = transactionTiles.objectStore("MapTiles");
    for (const tileCoord of tileCoordsArray) {
      const tileKey = "T_" + tileCoord[0] + "_" + tileCoord[1] + "_" + tileCoord[2];
      // console.log("ManageMaps:onCompleteDownloadClick-tileKey:", tileKey);
      var request = objectStoreTiles.openCursor(tileKey);
      request.onsuccess = (event) => {
        // console.log("ManageMaps:onCompleteDownloadClick-ok-event:", event);
        var target = event.target;
        if (!(target as any).result) {
          this.downloadTile(source, tileCoord);
        }
      };
      request.onerror = (event) => {
        console.log("ManageMaps:onCompleteDownloadClick-err-event:", event);
      };
    }
  }
  private async downloadTile(source: VectorTileSource, tileCoord: number[]) {
    console.log("ManageMaps:downloadTile-tileCoord", tileCoord);
    const zoom = tileCoord[0];
    setTimeout(() => {
      this.tilesToSave++;
      const img = document.createElement("img");
      // console.log("ManageMaps:downloadTile-img", img);
      img.onload = () => {
        let error = false;
        const canvas = document.createElement("canvas");
        // console.log("ManageMaps:downloadTile-onLoad-canvas", canvas);
        // canvas.width = source.getTileGrid().getTileSize(zoom);
        // canvas.height = source.getTileGrid().getTileSize(zoom);
        const tileSize = source.getTileGrid().getTileSize(zoom);
        if (typeof (tileSize) === "number") {
          canvas.width = tileSize;
          canvas.height = tileSize;
        }
        if (typeof (tileSize) !== "number") {
          const size = tileSize as Size;
          canvas.width = size[0];
          canvas.height = size[1];
        }
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        const itemKey = "T_" + tileCoord[0] + "_" + tileCoord[1] + "_" + tileCoord[2];
        // const itemKey = "X_" + tileCoord[0] + "_" + tileCoord[1] + "_" + tileCoord[2];
        // console.log("ManageMaps:downloadTile-onLoad-itemKey", itemKey);
        const tileTransaction = this.indexedDb.transaction("MapTiles", "readwrite");
        tileTransaction.oncomplete = (event) => {
          // console.log("ManageMaps:downloadTile-transaction.oncomplete-event", event);
        };
        tileTransaction.onerror = (event) => {
          // console.log("ManageMaps:downloadTile-transaction.onerror-event", event);
          error = true;
          this.faultCount++;
        };
        const tileObjectStore = tileTransaction.objectStore("MapTiles");
        try {
          const tile = {} as DbTile;
          tile.key = itemKey;
          tile.data = canvas.toDataURL();
          // console.log("ManageMaps:onDownloadMapClick-tile", tile);
          const request = tileObjectStore.put(tile);
          // console.log("ManageMaps:downloadMapIndexedDb-request:", request);
          request.onsuccess = (event) => {
            this.tilesSaved++;
            this.countStoredTiles++;
            this.lastSuccess = new Date(Date.now());
            if (this.tilesToSave === this.tilesSaved) {
              this.isDownloadInProgress = false;
              this.currentMap.downloadOk = true;
              this.storeMap(this.currentMap);
            }
          }
          request.onerror = (event) => {
            console.log("ManageMaps:downloadMapIndexedDb-request.onerror:", event);
            error = true;
            this.faultCount++;
          };
        } catch (err) {
          console.log("ManageMaps:downloadMapIndexedDb-catchError:", err);
          error = true;
          this.faultCount++;
        }
        img.remove();
        canvas.remove();
      };
      img.crossOrigin = "Anonymous";
      // §tocheck 6.5.0 -> 9.1
      img.src = source.getTileUrlFunction()(tileCoord, null, null);
    });

  }


  private calculateMapExtent(map: OfflineMap) {
    const extent = createEmpty();
    // console.log("ManageMaps:calculateMapExtent-extent0", extent);
    const tileGrid = this.serverMapSource.getTileGrid();
    let minX = Number.MAX_SAFE_INTEGER;
    let maxX = Number.MIN_SAFE_INTEGER;
    let minY = Number.MAX_SAFE_INTEGER;
    let maxY = Number.MIN_SAFE_INTEGER;
    for (const tile of map.mapTiles) {
      const zoom = tile[0];
      if (zoom === map.maxZoom) {
        if (tile[1] < minX) { minX = tile[1]; }
        if (tile[1] > minX) { maxX = tile[1]; }
        if (tile[2] < minY) { minY = tile[2]; }
        if (tile[2] > minY) { maxY = tile[2]; }
      }
    }
    // console.log("ManageMaps:calculateMapExtent-minX", minX);
    // console.log("ManageMaps:calculateMapExtent-minY", minY);
    const tempExt = createEmpty();
    let ext = tileGrid.getTileCoordExtent([map.maxZoom, minX, maxY], tempExt);
    // console.log("ManageMaps:calculateMapExtent-extMin", ext);
    const longitudeMin = ext[0];
    const latitudeMin = ext[1];
    // console.log("ManageMaps:calculateMapExtent-latitude", latitudeMin);
    const coordLL = toLonLat([longitudeMin, latitudeMin]);
    ext = tileGrid.getTileCoordExtent([map.maxZoom, maxX, minY], tempExt);
    // console.log("ManageMaps:calculateMapExtent-extMax", ext);
    const longitudeMax = ext[2];
    const latitudeMax = ext[3];
    // console.log("ManageMaps:calculateMapExtent-latitude", latitudeMax);
    const coordUR = toLonLat([longitudeMax, latitudeMax]);
    const extLonLat = createEmpty();
    extLonLat[0] = coordLL[0];
    extLonLat[1] = coordLL[1];
    extLonLat[2] = coordUR[0];
    extLonLat[3] = coordUR[1];
    // console.log("ManageMaps:calculateMapExtent-extentLonLat", extLonLat);
    return extLonLat;
  }

  private async storeMap(map: OfflineMap) {
    // console.log("ManageMaps:storeMap-newMap", newMap);
    const tile = {} as DbTile;
    tile.key = map.name;
    tile.data = JSON.stringify(map);
    // console.log("ManageMaps:storeTileCoordArray-tile", tile);
    const mapsTransaction = this.indexedDb.transaction("Maps", "readwrite");
    const mapsObjectStore = mapsTransaction.objectStore("Maps");
    const request = mapsObjectStore.put(tile);
    // console.log("ManageMaps:downloadMapIndexedDb-request:", request);
    request.onerror = (event) => {
      console.log("ManageMaps:storeMap-request.onerror:", event);
    };
  }

  public onRemoveMapClick(mapKey: string) {
    // console.log("ManageMaps:onRemoveMapClick-mapKey", mapKey);
    const transactionMaps = this.indexedDb.transaction("Maps", "readonly");
    const objectStoreMaps = transactionMaps.objectStore("Maps");
    const getRequest = objectStoreMaps.get(mapKey);
    getRequest.onsuccess = (event) => {
      const tile = getRequest.result;
      // console.log("ManageMaps:onRemoveMapClick-tile", tile);
      const map = JSON.parse(tile.data);
      const tileCoordsArray = map.mapTiles;
      const transactionTiles = this.indexedDb.transaction(["MapTiles"], "readwrite");
      const objectStoreTiles = transactionTiles.objectStore("MapTiles");
      for (const tileCoord of tileCoordsArray) {
        const tileKey = "T_" + tileCoord[0] + "_" + tileCoord[1] + "_" + tileCoord[2];
        const deleteTileRequest = objectStoreTiles.delete(tileKey);
        deleteTileRequest.onsuccess = (event1) => {
          // console.log("Objekt mit Schlüssel " + tileKey + " wurde gelöscht.");
        };
      }
      const transactionMaps1 = this.indexedDb.transaction("Maps", "readwrite");
      const objectStoreMaps1 = transactionMaps1.objectStore("Maps");
      const deleteMapRequest = objectStoreMaps1.delete(mapKey);
      deleteMapRequest.onsuccess = (event1) => {
        // console.log("ManageMaps:onRemoveMapClick-mapKey deleted", mapKey);
        const index = this.getIndexOfStoredMap(mapKey);
        // console.log("ManageMaps:onRemoveMapClick-index", index);
        this.storedMaps.splice(index, 1);
        // console.log("ManageMaps:onRemoveMapClick-storedMaps", this.storedMaps);
        this.countStoredMaps--;
        if (this.isActiveOfflineMap) { this.redrawStoredMapExtents(); }
        this.requestCountTilesFromIndexDb();
        this.tilesSaved = 0;
        if (!this.countStoredMaps) {
          this.activateOnlineMap.emit();
        }
      };
    };

  }
  private getIndexOfStoredMap(mapName: string) {
    console.log("ManageMaps:getIndexOfStoredMap-mapName", mapName);
    let index = -1;
    for (const map of this.storedMaps) {
      if (map.name === mapName) {
        index = this.storedMaps.indexOf(map);
        break;
      }
    }
    return index;
  }

  public onZoomToMapClick(mapKey: string) {
    const index = this.getIndexOfStoredMap(mapKey);
    const map = this.storedMaps[index];
    this.zoomToMapExtent.emit(map.mapExtent);
  }

  private redrawStoredMapExtents() {
    this.mapExtentSource.clear();
    for (const map of this.storedMaps) {
      this.drawMapExtent(map);
    }
  }
  private drawMapExtent(map: OfflineMap) {
    // console.log("ManageMaps:drawMapExtent-map", map);
    const extent = map.mapExtent;
    const coordLL = fromLonLat([extent[0], extent[1]]);
    const coordUL = fromLonLat([extent[0], extent[3]]);
    const coordUR = fromLonLat([extent[2], extent[3]]);
    const coordLR = fromLonLat([extent[2], extent[1]]);
    // console.log("ManageMaps:drawMapExtent-coords", coords);
    const polyCoords = [
      [[coordLL[0], coordLL[1]], [coordUL[0], coordUL[1]], [coordUR[0], coordUR[1]], [coordLR[0], coordLR[1]], [coordLL[0], coordLL[1]]],
    ];
    // console.log("ManageMaps:drawMapExtent-polyCoords", polyCoords);
    const extentFeature = new Feature({
      geometry: new Polygon(polyCoords),
      name: map.name,
    });
    // console.log("ManageMaps:drawMapExtent-extentFeature", extentFeature);
    this.mapExtentSource.addFeature(extentFeature);
  }

  public async downloadMapToIndexedDb_old() {
    // console.log("ManageMaps:downloadMapIndexedDb-mapRef", this.mapRef);
    this.currentMap = undefined;
    this.isDownloadInProgress = true;
    const mapType = this.downloadMapType;
    const source = this.downloadMapSource;
    // console.log("ManageMaps:downloadMapIndexedDb-source", source);
    const view = this.map.getView();
    // console.log("ManageMaps:downloadMapIndexedDb-view", view);
    let extent = this.mapExtent;
    console.log("ManageMaps:downloadMapIndexedDb-extent1", extent);
    if (!isFinite(extent[0])) { extent = view.calculateExtent(this.map.getSize()); }
    console.log("ManageMaps:downloadMapIndexedDb-extent2", extent);
    const coordLL = toLonLat([extent[0], extent[1]]);
    // console.log("ManageMaps:downloadMapIndexedDb-coordLL", coordLL);
    const coordUR = toLonLat([extent[2], extent[3]]);
    // console.log("ManageMaps:downloadMapIndexedDb-coordUR", coordUR);
    this.tilesToSave = 0;
    this.tilesSaved = 0;
    this.faultCount = 0;
    // tile-array
    const tileCoordArray = new Array();
    let currentZoom = view.getZoom();
    // console.log("ManageMaps:downloadMapIndexedDb-currentZoom", currentZoom);
    currentZoom = Math.round(currentZoom);
    // const minZoom = 3;
    const minZoom = currentZoom;
    let maxZoom = currentZoom + 4;
    if (maxZoom > 18) { maxZoom = 18; }
    // calculate corrected maxZoom
    const maxTiles = 2000;
    // const maxTiles = 500;
    let maxZoomCorr = minZoom;
    let toManyTiles = false;
    for (let zoom = minZoom; zoom <= maxZoom; zoom++) {
      // console.log("ManageMaps:downloadMapIndexedDb-zoom", zoom);
      // console.log("ManageMaps:downloadMapIndexedDb-tilesToSave", this.tilesToSave);
      if (toManyTiles) { break; }
      source.getTileGrid().forEachTileCoord(extent, zoom, (tileCoord) => {
        if (this.tilesToSave < maxTiles) {
          this.tilesToSave++;
        } else { toManyTiles = true; }
      });
      if (!toManyTiles) { maxZoomCorr = zoom; }
    }
    console.log("ManageMaps:downloadMapIndexedDb-maxZoomCorr", maxZoomCorr);
    // console.log("ManageMaps:downloadMapIndexedDb-tilesToSave2", this.tilesToSave);

    // loop for download timeout
    let lastAccess = new Date(Date.now());
    const maxIntervall = 120000;
    const intervalId = setInterval(() => {
      const now = new Date(Date.now());
      if (now.getTime() - lastAccess.getTime() > maxIntervall) {
        this.isDownloadInProgress = false;
        this.faultCount = this.tilesToSave - this.tilesSaved;
        clearInterval(intervalId);
      }
    }, 1000);
    this.tilesToSave = 0;
    for (let zoom = minZoom; zoom <= maxZoomCorr; zoom++) {
      // console.log("ManageMaps:downloadMapIndexedDb-zoom1", zoom);
      source.getTileGrid().forEachTileCoord(extent, zoom, (tileCoord) => {
        // console.log("ManageMaps:downloadMapIndexedDb-tileCoord", tileCoord);
        tileCoordArray.push(tileCoord);
        setTimeout(() => {
          this.tilesToSave++;
          const img = document.createElement("img");
          // console.log("ManageMaps:downloadMapIndexedDb-img", img);
          img.onload = () => {
            let error = false;
            const canvas = document.createElement("canvas");
            // console.log("ManageMaps:downloadMapIndexedDb-onLoad-canvas", canvas);
            // canvas.width = source.getTileGrid().getTileSize(zoom);
            // canvas.height = source.getTileGrid().getTileSize(zoom);
            const tileSize = source.getTileGrid().getTileSize(zoom);
            if (typeof (tileSize) === "number") {
              canvas.width = tileSize;
              canvas.height = tileSize;
            }
            if (typeof (tileSize) !== "number") {
              const size = tileSize as Size;
              canvas.width = size[0];
              canvas.height = size[1];
            }
            const ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0);
            const itemKey = "T_" + tileCoord[0] + "_" + tileCoord[1] + "_" + tileCoord[2];
            // const itemKey = "X_" + tileCoord[0] + "_" + tileCoord[1] + "_" + tileCoord[2];
            // console.log("ManageMaps:downloadMapIndexedDb-onLoad-itemKey", itemKey);
            const tileTransaction = this.indexedDb.transaction("MapTiles", "readwrite");
            tileTransaction.onerror = (event) => {
              // console.log("ManageMaps:downloadMapIndexedDb-transaction.onerror-event", event);
              error = true;
              this.faultCount++;
            };
            tileTransaction.oncomplete = (event) => {
              // console.log("ManageMaps:downloadMapIndexedDb-transaction.oncomplete-event", event);
              this.tilesSaved++;
              // console.log("ManageMaps:downloadMapIndexedDb-transaction.oncomplete-tilesSaved", this.tilesSaved);
              this.countStoredTiles++;
              lastAccess = new Date(Date.now());
              if (this.tilesToSave === this.tilesSaved) {
                this.isDownloadInProgress = false;
                this.currentMap.downloadOk = true;
                this.storeMap(this.currentMap);
              }
            };
            const tileObjectStore = tileTransaction.objectStore("MapTiles");
            try {
              const tile = {} as DbTile;
              tile.key = itemKey;
              tile.data = canvas.toDataURL();
              // console.log("ManageMaps:onDownloadMapClick-tile", tile);
              const request = tileObjectStore.put(tile);
              // console.log("ManageMaps:downloadMapIndexedDb-request:", request);
              request.onerror = (event) => {
                console.log("ManageMaps:downloadMapIndexedDb-request.onerror:", event);
                error = true;
                this.faultCount++;
              };
            } catch (err) {
              console.log("ManageMaps:downloadMapIndexedDb-catchError:", err);
              error = true;
              this.faultCount++;
            }
            img.remove();
            canvas.remove();
          };
          img.crossOrigin = "Anonymous";
          // §tocheck 6.5.0 -> 9.1
          img.src = source.getTileUrlFunction()(tileCoord, null, null);
        });
      });
    }
    // store map to indexedDb
    console.log("ManageMaps:downloadMapIndexedDb-storeMap:");
    const newMap = {} as OfflineMap;
    newMap.name = this.downloadMapName;
    newMap.type = mapType;
    newMap.countMapTiles = tileCoordArray.length;
    newMap.mapTiles = tileCoordArray;
    newMap.maxZoom = maxZoomCorr;
    const mapExtent = this.calculateMapExtent(newMap);
    newMap.mapExtent = mapExtent;
    this.storedMaps.push(newMap);
    this.currentMap = newMap;
    await this.storeMap(newMap);
    this.countStoredMaps++;
    // clear mapName
    this.downloadMapName = undefined;
    // console.log("------------------------ManageMaps:downloadMapIndexedDb-end");
  }



}
