import { Component, OnInit, OnDestroy, ViewChild, LOCALE_ID, AfterViewInit, Input, Output, EventEmitter, ElementRef } from "@angular/core";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

import { getDeviceTypeName } from "src/app/utils/utils";
import { Track } from "src/app/models/track";
import { DomSanitizer, SafeValue } from "@angular/platform-browser";
import { convertTrackToGPXData } from "src/app/utils/utils-track";
import { TourData } from "src/app/models/tour-data";
import { User } from "src/dto.generated/api";
import { IScatterData, IScatterPoint } from "src/app/models/chart-types";


@Component({
  selector: "app-tracking-component",
  templateUrl: "./tracking.component.html",
  styleUrls: ["./tracking.component.scss"]
})
export class TrackingComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() loggedInUser: User;
  @Input() isTestUser: boolean;
  @Input() languageCode: string;
  @Input() uiType: string;
  @Input() hasGeolocation: any;

  @Input() tourData: TourData;

  @Output() back: EventEmitter<void> = new EventEmitter();

  @Output() startTour: EventEmitter<void> = new EventEmitter();
  @Output() stopTour: EventEmitter<void> = new EventEmitter();
  @Output() saveTour: EventEmitter<void> = new EventEmitter();

  @Output() showTrackChanged: EventEmitter<boolean> = new EventEmitter();
  @Output() zoomToTrack: EventEmitter<void> = new EventEmitter();

  // elevation-chart
  public hasElevationData: boolean;
  public elevationChartData: IScatterData;
  public showElevationProfile: boolean;

  // public tourName: string;
  public fileUrlTrack: SafeValue;

  constructor(
    private sanitizer: DomSanitizer,
  ) {
  }

  public async ngOnInit() {
    // console.log("Tracking:ngOnInit-tourData", this.tourData);

    // if (this.loggedInUser) {
    //   if (this.loggedInUser.id === 1) { this.isTestUser = true; }
    // }
  }

  public ngAfterViewInit() {
    // console.log("Tracking:ngAfterViewInit");
  }
  public ngOnDestroy(): void {
  }

  public onTrackingBackClick() {
    this.back.emit();
  }

  public onSetRoutePlannerMoveType(moveType: string) {
    this.tourData.moveType = moveType;
  }

  public onStartTourClick() {
    const now = new Date(Date.now());
    const dateString = now.getFullYear() + "-" + (now.getMonth() + 1) + "-" + now.getDate();
    this.tourData.name = "Tour_" + dateString;
    this.startTour.emit();
  }
  public onStopTourClick() {
    this.stopTour.emit();
  }

  public onShowTrackChanged() {
    if (this.tourData.showTrack) { this.showTrackChanged.emit(true); }
    if (!this.tourData.showTrack) { this.showTrackChanged.emit(false); }
  }

  public onZoomToTrackClick() {
    this.zoomToTrack.emit();
  }

  public onShowElevationProfileClick() {
    this.showElevationProfile = !this.showElevationProfile;
    if (!this.showElevationProfile) { return; }
    if (!this.tourData.trackPoints[0].ele) return;
    this.hasElevationData = true;
    this.generateElevationChartData();
  }

  public onSaveTourClick() {
    this.saveTour.emit();

  }

  private generateElevationChartData() {
    // console.log("Tracking:generateElevationChartData-tourData", this.tourData);
    let trpLast = null;
    let sumDist = 0.0;
    this.elevationChartData = ({} as IScatterData);
    this.elevationChartData.points = new Array<IScatterPoint>();
    for (const trp of this.tourData.trackPoints) {
      if (trpLast) {
        const deltaX = trp.lng - trpLast.lng;
        const deltaY = trp.lat - trpLast.lat;
        const dist = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
        sumDist += dist;
      }
      const elevation = trp.ele;
      const point = {} as IScatterPoint;
      point.x = sumDist;
      point.y = elevation;
      this.elevationChartData.points.push(point);
      trpLast = trp;
    }
    // console.log("Tracking:generateChartData-sumDist", sumDist);
    const fact = this.tourData.trackLength / sumDist;
    // console.log("Tracking:generateChartData-fact", fact);
    for (const p of this.elevationChartData.points) {
      p.x = p.x * fact;
      p.x = Math.round(p.x * 1000) / 1000;
    }
    // console.log("Tracking:generateChartData-elevationData", this.elevationChartData);
  }

  public onSaveTrackAsGpxClick() {
    console.log("Tracking:onSaveTrackAsGPXClick-tourData", this.tourData);
    if (!this.tourData.trackPoints.length) { return; }
    const activeTrack = {} as Track;
    activeTrack.trackPointsType = "track";
    activeTrack.trackPoints = this.tourData.trackPoints;
    activeTrack.name = this.tourData.name;
    const data = convertTrackToGPXData(activeTrack);
    // console.log("Tracking:onSaveTrackAsGPXClick-data", data);
    const blob = new Blob([data], { type: "application/octet-stream" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    if (!this.tourData.name) { this.tourData.name = "my_Track"; }
    const fileName = this.tourData.name + ".gpx";
    link.download = fileName;
    link.click();
    window.URL.revokeObjectURL(url);
  }

  public onSaveTrackAsGpxClick_Old() {
    console.log("Tracking:onSaveTrackAsGPXClick-toureData", this.tourData);
    if (!this.tourData.trackPoints.length) { return; }
    const activeTrack = {} as Track;
    activeTrack.trackPointsType = "track";
    activeTrack.trackPoints = this.tourData.trackPoints;
    activeTrack.name = this.tourData.name;
    const data = convertTrackToGPXData(activeTrack);
    // console.log("Tracking:onSaveTrackAsGPXClick-data", data);
    const blob = new Blob([data], { type: "application/octet-stream" });
    this.fileUrlTrack = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
    setTimeout(() => { });
  }
}
