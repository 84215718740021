<!-- frequentlyasked-questions -->
<div #faqId style="margin:1.0rem;">

  <!-- language de -->
  <ng-container *ngIf="languageCode==='de'">
    <h1>Häufig gestellte Fragen</h1>

    <h2>Allgemeine Fragen</h2>

    <cdk-accordion class="accordion" multi="false">
      <cdk-accordion-item #accordionItemA0="cdkAccordionItem" class="accordion-item" role="button" tabindex="0"
        [attr.id]="'accordion-header-A0'" [attr.aria-expanded]="accordionItemA0.expanded"
        [attr.aria-controls]="'accordion-body-A0'">
        <div class="accordion-item-header" (click)="accordionItemA0.toggle()">
          Ist die Nutzung von Trip4You-Maps gratis?
          <span class="accordion-item-description">
            Zum {{ accordionItemA0.expanded ? 'Schließen' : 'Öffnen' }} klicken
          </span>
        </div>
        <div class="accordion-item-body" role="region" [style.display]="accordionItemA0.expanded ? '' : 'none'"
          [attr.id]="'accordion-body-A0'" [attr.aria-labelledby]="'accordion-header-A0'">
          Ja, die Nutzung von Trip4YouMaps ist komplett gratis.
          Derzeit ist die App sogar ohne Werbung.
          Bei steigenden Serverkosten sind allerdings Werbung und/oder Affiliate-Links geplant.
        </div>
      </cdk-accordion-item>

      <cdk-accordion-item #accordionItemA1="cdkAccordionItem" class="accordion-item" role="button" tabindex="0"
        [attr.id]="'accordion-header-A1'" [attr.aria-expanded]="accordionItemA1.expanded"
        [attr.aria-controls]="'accordion-body-A1'">
        <div class="accordion-item-header" (click)="accordionItemA1.toggle()">
          Was geschieht mit meinen Daten?
          <span class="accordion-item-description">
            Zum {{ accordionItemA1.expanded ? 'Schließen' : 'Öffnen' }} klicken
          </span>
        </div>
        <div class="accordion-item-body" role="region" [style.display]="accordionItemA1.expanded ? '' : 'none'"
          [attr.id]="'accordion-body-A1'" [attr.aria-labelledby]="'accordion-header-A1'">
          Wir nutzen deine Daten nur für die Verbesserung der App. <br />
          Wir geben deine Daten nicht an Dritte weiter. <br />
          Wir verdienen kein Geld mit deinen Daten.
          Details Zum Datenschutz findest du auf dieser Seite unter dem Register 'Datenschutz'.
        </div>
      </cdk-accordion-item>
    </cdk-accordion>

    <h2>Fragen zur Bedienung von Trip4YouMaps</h2>

    <cdk-accordion class="accordion" multi="false">
      <cdk-accordion-item #accordionItemB0="cdkAccordionItem" class="accordion-item" role="button" tabindex="0"
        [attr.id]="'accordion-header-B0'" [attr.aria-expanded]="accordionItemB0.expanded"
        [attr.aria-controls]="'accordion-body-B0'">
        <div class="accordion-item-header" (click)="accordionItemB0.toggle()">
          Kann ich Trip4You-Maps installieren?
          <span class="accordion-item-description">
            Zum {{ accordionItemB0.expanded ? 'Schließen' : 'Öffnen' }} klicken
          </span>
        </div>
        <div class="accordion-item-body" role="region" [style.display]="accordionItemB0.expanded ? '' : 'none'"
          [attr.id]="'accordion-body-B0'" [attr.aria-labelledby]="'accordion-header-B0'">
          Trip4YouMaps ist eine PWA (Progressive Web App).<br>
          Du kannst die App auf deinem Gerät installieren.<br>
          Die Installation ist je nach verwendetem Browser und Betriebssystem unterschiedlich.<br>
          <div style="margin-top:0.5rem;">
            <span style="font-size:large;"><b>Installation mit Chrome auf Android</b></span><br>
            <span>- Lade 'https://maps.trip4you.net' in Chrome</span><br>
            <span>- Im Menü von Chrome (3 Punkte) wähle 'App installieren'</span>
          </div>
          <div style="margin-top:0.5rem;">
            <span style="font-size:large;"><b>Installation mit Safari auf IOS</b></span><br>
            <span>- Lade 'https://maps.trip4you.net' in Safari</span><br>
            <span>- Wähle 'Teilen'</span><br>
            <span>- Wähle 'Zum Home-Bildschirm"</span>
          </div>
          <div style="margin-top:0.5rem;">
            <span style="font-size:large;"><b>Installation mit Chrome unter Windows</b></span><br>
            <span>- Lade 'https://maps.trip4you.net' in Chrome</span><br>
            <span>- Im Menü von Chrome wähle 'Trip4You - Maps installieren'</span>
          </div>
        </div>
      </cdk-accordion-item>

      <cdk-accordion-item #accordionItemB1="cdkAccordionItem" class="accordion-item" role="button" tabindex="0"
        [attr.id]="'accordion-header-B1'" [attr.aria-expanded]="accordionItemB1.expanded"
        [attr.aria-controls]="'accordion-body-B1'">
        <div class="accordion-item-header" (click)="accordionItemB1.toggle()">
          Trip4YouMaps zeigt 'Die Standortbestimmung ist nicht verfügbar!' an – Wie kann ich den Standort aktivieren?
          <span class="accordion-item-description">
            Zum {{ accordionItemB1.expanded ? 'Schließen' : 'Öffnen' }} klicken
          </span>
        </div>
        <div class="accordion-item-body" role="region" [style.display]="accordionItemB1.expanded ? '' : 'none'"
          [attr.id]="'accordion-body-B1'" [attr.aria-labelledby]="'accordion-header-B1'">
          Du musst den Standort in den Geräteeinstellungen, in den Browsereinstellungen und manchmal auch noch in den
          Website-Einstellungen des Browsers aktivieren, bzw. erlauben.
          <div style="margin-top:0.5rem;">
            <span style="font-size:large;"><b>In Chrome auf Android</b></span><br>
            <span>Normalerweise sollte Chrome dich auffordern, den Standort für die Website zuzulassen.</span><br>
            <span>Wenn nicht, überprüfe die folgenden Einstellungen.</span><br>
            <span>In den Geräteeinstellungen (Handyeinstellungen) - Standort</span><br>
            <span>- Schalte den Standort auf 'an'</span><br>
            <span>- Prüfe, ob der Modus für den Standort auf 'Hohe Genauigkeit' gestellt ist</span><br>
            <span>- Sonst setze den Modus auf 'Hohe Genauigkeit'</span><br>
            <span>In den Einstellungen für Chrome (Chrome Menü, dann 'Einstellungen' wählen)</span><br>
            <span>- In 'Website-Einstellungen' unter 'Standort' sollte der Standort auf 'Nachfragen' gesetzt
              sein</span><br>
            <span>- Prüfe, ob 'https://maps.trip4you.net' unter den zugelassenen Websites ist</span><br>
            <span>- Wenn 'https://maps.trip4you.net' unter den blockierten Websites ist, klicke drauf und wähle dann auf
              'zulassen'</span>
            <span>- Nach Änderung in Trip4YouMaps Meldungsfenster schließen und auf das Standort-Symbol klicken</span>
            <span>- Trip4YouMaps danach neu starten, falls der Standort immer noch nicht funktioniert.</span>
          </div>
          <div style="margin-top:0.5rem;">
            <span style="font-size:large;"><b>In Safari on IOS</b></span><br>
            <span>Manchmal aktiviert Safari den Standort einer Website nicht, obwohl die Locator-Einstellungen von
              Safari auf „Zulassen“ oder „Anfragen“ eingestellt sind. Dann</span><br>
            <span>- Gehe in die Einstellungen</span><br>
            <span>- Wähle Datenschutz und Sicherheit</span><br>
            <span>- Wähle Ortungsdienste</span><br>
            <span>- Schalte den Schalter für Ortungsdienste ein, wenn er auf aus ist</span><br>
            <span>- Scrolle nach unten und tippe auf Safari-Websites</span>
            <span>- Scrolle nach unten zur Website 'Trip4YouMaps'</span><br>
            <span>- Stelle sicher, dass unter 'Standortzugriff zulassen' die Option 'Während der Verwendung der App'
              ausgewählt ist.</span><br>
            <span>- Sollte der Standort immer noch nicht verfügbar sein, wenden Sie sich bitte an
              travelplanner4you@outlook.com</span>
          </div>
          <div style="margin-top:0.5rem;">
            <span style="font-size:large;"><b>In Chrome on Windows</b></span><br>
            <span>Normalerweise sollte Chrome dich auffordern, den Standort für die Website zuzulassen.</span><br>
            <span>Wenn nicht, überprüfe die folgenden Einstellungen.</span><br>
            <span>In den Chrome 'Einstellungen'</span><br>
            <span>- Wähle 'Datenschutz und Sicherheit'</span><br>
            <span>- Wähle 'Website-Einstellungen'</span><br>
            <span>- Wähle 'Standort'</span><br>
            <span>- Klicke auf den Eintrag 'https://maps.trip4you.net'</span><br>
            <span>- Setze die Standortberechtigung auf 'Zulassen'</span>
          </div>
        </div>
      </cdk-accordion-item>

      <!-- share location -->
      <div #scrollB3></div>
      <cdk-accordion-item #accordionItemB3="cdkAccordionItem" class="accordion-item" role="button" tabindex="0"
        [attr.id]="'accordion-header-B3'" [attr.aria-expanded]="accordionItemB3.expanded"
        [expanded]="accordionItemB3Expanded" [attr.aria-controls]="'accordion-body-B3'">
        <div class="accordion-item-header" (click)="accordionItemB3.toggle()">
          Wie kann ich meinen Standort teilen?
          <span class="accordion-item-description">
            Zum {{ accordionItemB3.expanded ? 'Schließen' : 'Öffnen' }} klicken
          </span>
        </div>
        <div class="accordion-item-body" role="region" [style.display]="accordionItemB3.expanded ? '' : 'none'"
          [attr.id]="'accordion-body-B3'" [attr.aria-labelledby]="'accordion-header-B3'">
          Um den Standort zu teilen musst du eingeloggt sein!<br>
          Klicke einfach auf das Standortsymbol auf der Karte.<br>
          Es erscheint ein Popup-Menü in welchem du "Meinen Standort teilen" auswählen kannst.<br>
          Danach kannst du im gewohnten Dialog zum Teilen auswählen wie du den Standort teilen willst.
        </div>
      </cdk-accordion-item>

      <!-- share live tracking key -->
      <div #scrollB4></div>
      <cdk-accordion-item #accordionItemB4="cdkAccordionItem" class="accordion-item" role="button" tabindex="0"
        [attr.id]="'accordion-header-B4'" [attr.aria-expanded]="accordionItemB4.expanded"
        [expanded]="accordionItemB4Expanded" [attr.aria-controls]="'accordion-body-B4'">
        <div class="accordion-item-header" (click)="accordionItemB4.toggle()">
          Wie kann ich Live-Tracking starten und an Freunde versenden?
          <span class="accordion-item-description">
            Zum {{ accordionItemB4.expanded ? 'Schließen' : 'Öffnen' }} klicken
          </span>
        </div>
        <div class="accordion-item-body" role="region" [style.display]="accordionItemB4.expanded ? '' : 'none'"
          [attr.id]="'accordion-body-B3'" [attr.aria-labelledby]="'accordion-header-B3'">
          Um Live-Tracking zu aktivieren und zu teilen musst du eingeloggt sein!<br>
          Klicke einfach auf das Standortsymbol auf der Karte.<br>
          Es erscheint ein Popup-Menü in welchem du "Live-Tracking starten und teilen" auswählen kannst.
          Danach erscheint ein Popub in welchem du noch Einstellungen für das Live-Tracking vornehmen und das Tracking
          starten kannst.
          Anschließend kannst du im gewohnten Dialog zum Teilen auswählen wie du den erzeugten Link für das Tracking
          teilen willst.
        </div>
      </cdk-accordion-item>

      <!-- additional info -->
      <cdk-accordion-item #accordionItemB2="cdkAccordionItem" class="accordion-item" role="button" tabindex="0"
        [attr.id]="'accordion-header-B2'" [attr.aria-expanded]="accordionItemB2.expanded"
        [attr.aria-controls]="'accordion-body-B2'">
        <div class="accordion-item-header" (click)="accordionItemB2.toggle()">
          Wo kann ich zusätzliche Informationen über die Bedienung von Trip4YouMaps finden?
          <span class="accordion-item-description">
            Zum {{ accordionItemB2.expanded ? 'Schließen' : 'Öffnen' }} klicken
          </span>
        </div>
        <div class="accordion-item-body" role="region" [style.display]="accordionItemB2.expanded ? '' : 'none'"
          [attr.id]="'accordion-body-B2'" [attr.aria-labelledby]="'accordion-header-B2'">
          Im Menü 'Hilfe' werden die verwendeten Symbole näher erklärt.<br>
          Wenn du spezielle Fragen über Trip4YouMaps hast, sende eine E-Mail an travelplanner4you@outlook.com.<br>
          In Wiki-OSM findest du nähere Informationen über Trip4YouMaps unter:
          <a href="https://wiki.openstreetmap.org/wiki/DE:Trip4YouMaps" target="_blank">Trip4YouMaps auf Wiki-OSM</a>
        </div>
      </cdk-accordion-item>

    </cdk-accordion>

    <h2>Fragen zum Einbinden der App in eigene Webseiten</h2>

    <cdk-accordion class="accordion" multi="false">

      <cdk-accordion-item #accordionItemC1="cdkAccordionItem" class="accordion-item" role="button" tabindex="0"
        [attr.id]="'accordion-header-C1'" [attr.aria-expanded]="accordionItemC1.expanded"
        [attr.aria-controls]="'accordion-body-C1'">
        <div class="accordion-item-header" (click)="accordionItemC1.toggle()">
          Kann ich Trip4YouMaps als interaktive Karte in meine Webseite einbinden um meinen Standort anzuzeigen?
          <span class="accordion-item-description">
            Zum {{ accordionItemC1.expanded ? 'Schließen' : 'Öffnen' }} klicken
          </span>
        </div>
        <div class="accordion-item-body" role="region" [style.display]="accordionItemC1.expanded ? '' : 'none'"
          [attr.id]="'accordion-body-C1'" [attr.aria-labelledby]="'accordion-header-C1'">
          Ja, das ist in jeder Webseite leicht möglich.<br />
          Für die Zustimmung und Anweisungen dazu sende einfach eine E-Mail an travelplanner4you@outlook.com<br />
          Du kannst den Service nach Anfrage gratis nutzen. Ohne Anfrage ist diese Nutzung nicht gestattet.
        </div>
      </cdk-accordion-item>

      <cdk-accordion-item #accordionItemC2="cdkAccordionItem" class="accordion-item" role="button" tabindex="0"
        [attr.id]="'accordion-header-C2'" [attr.aria-expanded]="accordionItemC2.expanded"
        [attr.aria-controls]="'accordion-body-C2'">
        <div class="accordion-item-header" (click)="accordionItemC2.toggle()">
          Kann ich Trip4YouMaps als interaktive Karte in meine Webseite einbinden um Wander- oder Radrouten anzuzeigen?
          <span class="accordion-item-description">
            Zum {{ accordionItemC2.expanded ? 'Schließen' : 'Öffnen' }} klicken
          </span>
        </div>
        <div class="accordion-item-body" role="region" [style.display]="accordionItemC2.expanded ? '' : 'none'"
          [attr.id]="'accordion-body-C2'" [attr.aria-labelledby]="'accordion-header-C2'">
          Ja, das ist in jeder Webseite leicht möglich.<br />
          Für die Zustimmung und Anweisungen dazu sende einfach eine E-Mail an travelplanner4you@outlook.com<br />
          Ohne Anfrage ist diese Nutzung nicht gestattet.
        </div>
      </cdk-accordion-item>
    </cdk-accordion>

  </ng-container>

  <!-- language en -->
  <ng-container *ngIf="languageCode!=='de'">
    <h1>Frequently asked questions</h1>

    <h2>General questions</h2>

    <cdk-accordion class="accordion" multi="false">
      <cdk-accordion-item #accordionItemA0="cdkAccordionItem" class="accordion-item" role="button" tabindex="0"
        [attr.id]="'accordion-header-A0'" [attr.aria-expanded]="accordionItemA0.expanded"
        [attr.aria-controls]="'accordion-body-A0'">
        <div class="accordion-item-header" (click)="accordionItemA0.toggle()">
          Is this service free?
          <span class="accordion-item-description">
            Click to {{ accordionItemA0.expanded ? 'close' : 'open' }}
          </span>
        </div>
        <div class="accordion-item-body" role="region" [style.display]="accordionItemA0.expanded ? '' : 'none'"
          [attr.id]="'accordion-body-A0'" [attr.aria-labelledby]="'accordion-header-A0'">
          Yes, Trip4You-Maps is free to use. <br>
          Currently there is no advertising.
        </div>
      </cdk-accordion-item>

      <cdk-accordion-item #accordionItemA1="cdkAccordionItem" class="accordion-item" role="button" tabindex="0"
        [attr.id]="'accordion-header-A1'" [attr.aria-expanded]="accordionItemA1.expanded"
        [attr.aria-controls]="'accordion-body-A1'">
        <div class="accordion-item-header" (click)="accordionItemA1.toggle()">
          What happens with my data?
          <span class="accordion-item-description">
            Click to {{ accordionItemA1.expanded ? 'close' : 'open' }}
          </span>
        </div>
        <div class="accordion-item-body" role="region" [style.display]="accordionItemA1.expanded ? '' : 'none'"
          [attr.id]="'accordion-body-A1'" [attr.aria-labelledby]="'accordion-header-A1'">
          We use your data only for services in this application. <br />
          We don't hand your data on to third parties. <br />
          We don't earn money with your data.
          Details on data protection can be found on this page under the 'Privacy policy' tab.
        </div>
      </cdk-accordion-item>
    </cdk-accordion>

    <h2>Questions about using the application</h2>

    <cdk-accordion class="accordion" multi="false">
      <cdk-accordion-item #accordionItemB0="cdkAccordionItem" class="accordion-item" role="button" tabindex="0"
        [attr.id]="'accordion-header-B0'" [attr.aria-expanded]="accordionItemB0.expanded"
        [attr.aria-controls]="'accordion-body-B0'">
        <div class="accordion-item-header" (click)="accordionItemB0.toggle()">
          Can I install Trip4You-Maps?
          <span class="accordion-item-description">
            Click to {{ accordionItemB0.expanded ? 'close' : 'open' }}
          </span>
        </div>
        <div class="accordion-item-body" role="region" [style.display]="accordionItemB0.expanded ? '' : 'none'"
          [attr.id]="'accordion-body-B0'" [attr.aria-labelledby]="'accordion-header-B0'">
          Trip4YouMaps is a PWA (Progressive Web App).<br>
          You can install the App on your device.<br>
          The installation is different on each type of browser and OS.<br>
          <div style="margin-top:0.5rem;">
            <span style="font-size:large;"><b>Installation with Chrome on Android</b></span><br>
            <span>- Load 'https://maps.trip4you.net' in Chrome</span><br>
            <span>- In menu of Chrome tap to 'Install App'</span>
          </div>
          <div style="margin-top:0.5rem;">
            <span style="font-size:large;"><b>Installation with Safari on IOS</b></span><br>
            <span>- Load 'https://maps.trip4you.net' in Safari</span><br>
            <span>- Go to 'Share'</span><br>
            <span>- Select 'Add to Home Screen'</span>
          </div>
          <div style="margin-top:0.5rem;">
            <span style="font-size:large;"><b>Installation with Chrome on Windows</b></span><br>
            <span>- Load 'https://maps.trip4you.net' in Chrome</span><br>
            <span>- In menu of Chrome tap to 'Install Trip4You - Maps ...'</span>
          </div>
        </div>
      </cdk-accordion-item>

      <cdk-accordion-item #accordionItemB1="cdkAccordionItem" class="accordion-item" role="button" tabindex="0"
        [attr.id]="'accordion-header-B1'" [attr.aria-expanded]="accordionItemB1.expanded"
        [attr.aria-controls]="'accordion-body-B1'">
        <div class="accordion-item-header" (click)="accordionItemB1.toggle()">
          Trip4YouMaps shows location not enabled - How can I enable the location?
          <span class="accordion-item-description">
            Click to {{ accordionItemB1.expanded ? 'close' : 'open' }}
          </span>
        </div>
        <div class="accordion-item-body" role="region" [style.display]="accordionItemB1.expanded ? '' : 'none'"
          [attr.id]="'accordion-body-B1'" [attr.aria-labelledby]="'accordion-header-B1'">
          You have to enable the location in the device settings, in the browser settings and sometimes in the website
          settings of the browser.
          <div style="margin-top:0.5rem;">
            <span style="font-size:large;"><b>In Chrome on Android</b></span><br>
            <span>Normaly Chrome should ask you to allow the location for the website.</span><br>
            <span>If not, check following settings.</span><br>
            <span>- Go to 'Settings' of youre device</span><br>
            <span>- Tap 'Location'</span><br>
            <span>- Switch to on</span><br>
            <span>- Search for setting the location accuracy</span><br>
            <span>- Set accuracy to high, if it isn't already set</span>
            <span>In Chrome settings</span><br>
            <span>- Select 'Website Settings'</span><br>
            <span>- Tap 'Location'</span><br>
            <span>- if maps.trip4you.net is in the blocked websites, click on it and allow the location for the
              website</span>
          </div>
          <div style="margin-top:0.5rem;">
            <span style="font-size:large;"><b>In Safari on IOS</b></span><br>
            <span>Sometimes Safari dosn't enable the location for a website, although the locator settings of Safari are
              set to allow or ask. Then</span><br>
            <span>- Go to 'Settings"</span>
            <span>- Tap 'Privacy & Security'</span><br>
            <span>- Go to 'Location Services'</span><br>
            <span>- In the top right, tap the switch to turn location on, if it's off</span><br>
            <span>- Scroll down, tap 'Safari Websites'</span>
            <span>- Scroll down to website 'Trip4YouMaps'</span><br>
            <span>- Under 'Allow location access', make sure it’s set to 'While using the App'</span><br>
            <span>- If the location is still not available, please contact travelplanner4you@outlook.com</span>
          </div>
          <div style="margin-top:0.5rem;">
            <span style="font-size:large;"><b>In Chrome on Windows</b></span><br>
            <span>Normaly Chrome should ask you to allow the location for the website. If not, check following
              settings.</span><br>
            <span>In the Chrome settings</span><br>
            <span>- Select 'Data protection & savety'</span><br>
            <span>- Tap on 'Website settings'</span><br>
            <span>- Tap on Locator</span><br>
            <span>- Tap on maps.trip4you.net</span><br>
            <span>- Set Location to 'Allow'</span>
          </div>
        </div>
      </cdk-accordion-item>

      <!-- share location -->
      <cdk-accordion-item #accordionItemB3="cdkAccordionItem" class="accordion-item" role="button" tabindex="0"
        [attr.id]="'accordion-header-B3'" [attr.aria-expanded]="accordionItemB3.expanded"
        [expanded]="accordionItemB3Expanded" [attr.aria-controls]="'accordion-body-B3'">
        <div class="accordion-item-header" (click)="accordionItemB3.toggle()">
          How can I share my location?
          <span class="accordion-item-description">
            Click to {{ accordionItemB3.expanded ? 'close' : 'open' }}
          </span>
        </div>
        <div class="accordion-item-body" role="region" [style.display]="accordionItemB3.expanded ? '' : 'none'"
          [attr.id]="'accordion-body-B3'" [attr.aria-labelledby]="'accordion-header-B3'">
          To share the location you have to be logged in!<br>
          Click on the location icon on the map.<br>
          In the appearing popup select "Share my location".<br>
          Then you can select how to share the location with the standard share dialog.
        </div>
      </cdk-accordion-item>

      <!-- share live tracking key -->
      <div #scrollB4></div>
      <cdk-accordion-item #accordionItemB4="cdkAccordionItem" class="accordion-item" role="button" tabindex="0"
        [attr.id]="'accordion-header-B4'" [attr.aria-expanded]="accordionItemB4.expanded"
        [expanded]="accordionItemB4Expanded" [attr.aria-controls]="'accordion-body-B4'">
        <div class="accordion-item-header" (click)="accordionItemB4.toggle()">
          How can I use live tracking and share a key with my friends?
          <span class="accordion-item-description">
            Click to {{ accordionItemB4.expanded ? 'close' : 'open' }}
          </span>
        </div>
        <div class="accordion-item-body" role="region" [style.display]="accordionItemB4.expanded ? '' : 'none'"
          [attr.id]="'accordion-body-B3'" [attr.aria-labelledby]="'accordion-header-B3'">
          To activate and share live tracking you must be logged in!<br>
          Just click on the location icon on the map.<br>
          A pop-up menu will appear in which you can select “Start and share live tracking”.
          Another pop-ub will appear in which you can make settings for live tracking and start tracking.
          You can then choose in the usual sharing dialog how you want to share the generated link for tracking.
        </div>
      </cdk-accordion-item>

      <!-- additional info -->
      <cdk-accordion-item #accordionItemB2="cdkAccordionItem" class="accordion-item" role="button" tabindex="0"
        [attr.id]="'accordion-header-B2'" [attr.aria-expanded]="accordionItemB2.expanded"
        [attr.aria-controls]="'accordion-body-B2'">
        <div class="accordion-item-header" (click)="accordionItemB2.toggle()">
          Where can I find aditional information using Trip4YouMaps?
          <span class="accordion-item-description">
            Click to {{ accordionItemB2.expanded ? 'close' : 'open' }}
          </span>
        </div>
        <div class="accordion-item-body" role="region" [style.display]="accordionItemB2.expanded ? '' : 'none'"
          [attr.id]="'accordion-body-B2'" [attr.aria-labelledby]="'accordion-header-B2'">
          The used symbols in Trip4YouMaps are explained in more detail in the 'Help' menu.<br>
          If you have specific questions about Trip4YouMaps, send an email to travelplanner4you@outlook.com.<br>
          In Wiki-OSM you can find more information about Trip4YouMaps
          <a href="https://wiki.openstreetmap.org/wiki/Trip4YouMaps" target="_blank">Trip4YouMaps on Wiki-OSM</a>
        </div>
      </cdk-accordion-item>

    </cdk-accordion>

    <h2>Questions about integrating the app into your own websites</h2>

    <cdk-accordion class="accordion" multi="false">

      <cdk-accordion-item #accordionItemC1="cdkAccordionItem" class="accordion-item" role="button" tabindex="0"
        [attr.id]="'accordion-header-C1'" [attr.aria-expanded]="accordionItemC1.expanded"
        [attr.aria-controls]="'accordion-body-C1'">
        <div class="accordion-item-header" (click)="accordionItemC1.toggle()">
          Can I embed Trip4YouMaps as an interactive map to show my location?
          <span class="accordion-item-description">
            Click to {{ accordionItemC1.expanded ? 'close' : 'open' }}
          </span>
        </div>
        <div class="accordion-item-body" role="region" [style.display]="accordionItemC1.expanded ? '' : 'none'"
          [attr.id]="'accordion-body-C1'" [attr.aria-labelledby]="'accordion-header-C1'">
          Yes, this is easily possible in any website.<br />
          For approval and instructions on how to do this, simply email travelplanner4you@outlook.com<br />
          You can use the service free of charge upon request. Use without request is not permitted.
        </div>
      </cdk-accordion-item>

      <cdk-accordion-item #accordionItemC2="cdkAccordionItem" class="accordion-item" role="button" tabindex="0"
        [attr.id]="'accordion-header-C2'" [attr.aria-expanded]="accordionItemA1.expanded"
        [attr.aria-controls]="'accordion-body-C2'">
        <div class="accordion-item-header" (click)="accordionItemC2.toggle()">
          Can I integrate Trip4YouMaps as an interactive map to display hiking or cycling routes?
          <span class="accordion-item-description">
            Click to {{ accordionItemC2.expanded ? 'close' : 'open' }}
          </span>
        </div>
        <div class="accordion-item-body" role="region" [style.display]="accordionItemC2.expanded ? '' : 'none'"
          [attr.id]="'accordion-body-C2'" [attr.aria-labelledby]="'accordion-header-C2'">
          Yes, this is easily possible in any website.<br />
          For approval and instructions on how to do this, simply email travelplanner4you@outlook.com<br />
          Use without request is not permitted.
        </div>
      </cdk-accordion-item>
    </cdk-accordion>

  </ng-container>

</div>