import { Component, OnInit, OnDestroy, Input, ViewChild, ElementRef, AfterViewInit } from "@angular/core";
import { ActivatedRoute, ParamMap } from "@angular/router";

@Component({
  selector: "app-info-faq-component",
  templateUrl: "./info-faq.component.html",
  styleUrls: ["./info-faq.component.scss"]
})
export class InfoFaqComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() uiType: string;
  @Input() languageCode: string;
  @Input() activate: string;


  public accordionItemB3Expanded = false;
  public accordionItemB4Expanded = false;

  @ViewChild("faqId") faqElement: ElementRef;
  @ViewChild("scrollB3") b3Element: ElementRef;
  @ViewChild("accordionItemB3") accB3Element: ElementRef;

  constructor(
  ) {
  }

  public async ngOnInit() {
    // this.languageCode = getUserLanguage();
    // this.languageCode = "en"; // until other versions available
    // console.log("Faq:ngOnInit", this.languageCode);
    console.log("Faq:ngOnInit-activate", this.activate);
    setTimeout(() => {
      if (this.activate === "faq-loc") {
        this.accordionItemB3Expanded = true;
        // this.accordionItemB4Expanded = true;
      }
    }, 1000);

  }

  public async ngAfterViewInit() {
    // console.log("Faq:ngAfterViewInit", this.languageCode);
    if (!this.activate) { this.faqElement.nativeElement.scrollIntoView({ behavior: "smooth" }); }
    if (this.activate === "faq-loc") {
      this.b3Element.nativeElement.scrollIntoView({ behavior: "smooth" });
    }
  }

  public ngOnDestroy(): void {
  }

  public async onRouteQueryParamsChanged(paramMap: ParamMap) {
    console.log("Faq:onRouteParamsChanged-paramMap", paramMap);
  }


}

