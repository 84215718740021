import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit, Input, ElementRef, Output, EventEmitter } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import Map from "ol/Map";

import { SearchFilter } from "src/app/models/search-parameter";
import { MapPlaceL } from "src/app/models/mapplace";
import { getTransformedViewExtent } from "../maps-utils/utils-maps-ol-map";
import { doORSGeocodeSearchAutocomplete } from "../maps-utils/utils-maps-ors";
import { LngLat } from "src/app/models/lnglat";
import { SavedPlaceCategory } from "src/app/models/saved-item-categories";
import { EnumActionType, EnumAppType, EnumGlobalStatusCode, UsageLogMaps } from "src/dto.generated/api";
import { GlobalService } from "src/app/services/global.service";
import { environment } from "src/environments/environment";
import { ApiError } from "src/app/models/api-error";
import { createApiError } from "src/app/utils/utils";

@Component({
  selector: "app-search-place-component",
  templateUrl: "./search-place.component.html",
  styleUrls: ["./search-place.component.scss"]
})
export class SearchPlaceComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() languageCode: string;
  @Input() uiType: string;
  @Input() selectType: string;
  @Input() searchParameter: SearchFilter;
  @Input() currentLocation: LngLat;
  @Input() foundPlaces: MapPlaceL[];
  @Input() savedPlaces: SavedPlaceCategory[];
  @Input() map: Map;
  @Input() currentMapExtent: number[];
  @Input() createPointOnMapActive: boolean;
  @Input() selectedPlace: MapPlaceL;
  @Input() sessionId: string;

  @Output() back: EventEmitter<void> = new EventEmitter();
  @Output() createPointOnMap: EventEmitter<void> = new EventEmitter();
  @Output() selectedPoint: EventEmitter<MapPlaceL> = new EventEmitter();
  @Output() routeToSelectedPoint: EventEmitter<MapPlaceL> = new EventEmitter();
  @Output() foundPlacesChange: EventEmitter<MapPlaceL[]> = new EventEmitter();
  @Output() showError: EventEmitter<ApiError> = new EventEmitter();

  public visibleSearchDialog = true;
  public visibleSettings: boolean;
  public visibleSavedPlaces: boolean;
  public placeNotFoundError: boolean;
  public visibleSearchHelp: boolean;

  // progress-spinner
  public isVisibleProgressSpinner: boolean;

  // error
  public orsPostErrorText: string;

  @ViewChild("searchDlg") searchDlgElement: ElementRef;
  @ViewChild("searchInput") searchInputElement: ElementRef;

  constructor(
    private http: HttpClient,
    private globalService: GlobalService,
  ) {
  }

  public async ngOnInit() {
    // console.log("SearchPlace:ngOnInit-uiType", this.uiType);
    // console.log("SearchPlace:ngOnInit-map", this.map);
    // console.log("SearchPlace:ngOnInit-searchParameter", this.searchParameter);
    // console.log("SearchPlace:ngOnInit-savedPlaces", this.savedPlaces);
    // console.log("SearchPlace:ngOnInit-foundPlaces", this.foundPlaces);
    // console.log("SearchPlace:ngOnInit-selectedPlace", this.selectedPlace);
    if (!this.selectedPlace) {
      this.selectedPlace = {} as MapPlaceL;
      this.selectedPlace.id = -1;
    }

    // for testing language en
    // this.languageCode = "en";
  }
  public ngAfterViewInit() {
    // console.log("SearchPlace:ngAfterViewInit");
    const element = this.searchInputElement.nativeElement;
    element.focus();
    this.scrollToDlg();
  }
  public ngOnDestroy(): void {
  }
  private scrollToDlg() {
    // this.searchDlgElement.nativeElement.scrollIntoView({ align: top, behavior: "smooth" });
  }


  public onSearchBackClick() {
    this.visibleSearchDialog = false;
    this.back.emit();
  }

  public onClearSearchStringClick() {
    this.searchParameter.text = "";
    this.foundPlaces = undefined;
    this.placeNotFoundError = false;
    const element = this.searchInputElement.nativeElement;
    element.focus();
  }

  public onSearchSettingsClick() {
    this.visibleSettings = !this.visibleSettings;
  }

  public onSearchSavedPlacesClick() {
    this.visibleSavedPlaces = !this.visibleSavedPlaces;
  }

  public async onSearchSimpleTextKeyUp(event: KeyboardEvent) {
    // console.log("SearchPlace:onSearchTextKeyUp", event);
    if (event.key === "Enter" || !event.key) {
      if (!this.searchParameter.text) {
        this.placeNotFoundError = true;
        return;
      }
      await this.simpleSearch(this.searchParameter);
      // console.log("SearchPlace:onSearchStringClick-placeNotFoundError", this.placeNotFoundError);
      if (!this.placeNotFoundError) {
        const element = this.searchInputElement.nativeElement;
        element.blur();
      }
      return;
    }
    this.placeNotFoundError = false;
  }
  public async simpleSearch(searchParameter: SearchFilter) {
    this.orsPostErrorText = undefined;
    this.createPointOnMapActive = false;
    let extent = getTransformedViewExtent(this.map);
    // console.log("SearchPlace:simpleSearch-extent", extent);
    if (extent[0] === extent[2] || extent[1] === extent[3]) { extent = this.currentMapExtent; }
    this.searchParameter.mapExtent = extent;
    // console.log("SearchPlace:simpleSearch-searchParameter", this.searchParameter);
    this.isVisibleProgressSpinner = true;
    const result = await doORSGeocodeSearchAutocomplete(this.http, this.currentLocation, searchParameter);
    // console.log("SearchPlace:simpleSearch-result", result);
    this.isVisibleProgressSpinner = false;
    if (result.type === "error") {
      if (this.languageCode === "de") {
        this.orsPostErrorText = "Internetverbindung prüfen!";
      } else {
        this.orsPostErrorText = "Check your internet connection!";
      }
      // this.isOnline = false;
      return;
    }
    if (result.code) {
      // §todo show result.message to user
      // this.orsPostErrorText = result.message;
      return;
    }

    if (result === undefined || !result.length) {
      this.foundPlaces = undefined;
      this.placeNotFoundError = true;
      const infoNotFound = "searchautocomplete: " + searchParameter.text + "- not found";
      this.createUsageLog(EnumActionType.call_ors_searchautocomplete, infoNotFound);
      return;
    }
    this.foundPlaces = result;
    this.foundPlaces.sort((a, b) => {
      if (a.distance > b.distance) { return 1; }
      else { return -1; }
    });
    // console.log("SearchPlace:simpleSearch-foundPlaces", this.foundPlaces);
    if (this.selectedPlace) { this.selectedPlace.id = -1; }
    this.foundPlacesChange.emit(this.foundPlaces);
    const infoFound = "searchautocomplete: " + searchParameter.text + " - found: " + this.foundPlaces.length;
    this.createUsageLog(EnumActionType.call_ors_searchautocomplete, infoFound);
  }

  public async onSearchStringClick() {
    // console.log("SearchPlace:onSearchStringClick-searchParameter", this.searchParameter);
    if (!this.searchParameter.text) {
      this.placeNotFoundError = true;
      setTimeout(() => {
        const element = this.searchInputElement.nativeElement;
        element.focus();
      });
      return;
    }
    await this.simpleSearch(this.searchParameter);
    if (!this.placeNotFoundError) {
      const element = this.searchInputElement.nativeElement;
      element.blur();
    }
  }

  public onSelectSearchResult(mapPlace: MapPlaceL) {
    this.selectedPlace = mapPlace;
    // console.log("SearchPlace:onSelectSearchResult-selectedPlace", this.selectedPlace);
    this.visibleSearchDialog = false;
    this.selectedPoint.emit(this.selectedPlace);
  }

  public onRouteToSearchResult(mapPlace: MapPlaceL) {
    this.selectedPlace = mapPlace;
    // console.log("SearchPlace:onSelectSearchResult-selectedPlace", this.selectedPlace);
    this.visibleSearchDialog = false;
    this.routeToSelectedPoint.emit(this.selectedPlace);
  }

  public onSelectSavedPlace(mapPoint: MapPlaceL) {
    console.log("SearchPlace:onSelectsavedPlace-mapPoint", mapPoint);
    const selectedRefPlace = this.clonePlace(mapPoint);
    // console.log("SearchPlace:onSelectsavedPlace-selectedRefPlace", selectedRefPlace);
    this.visibleSearchDialog = false;
    this.selectedPoint.emit(selectedRefPlace);
  }

  public onCreateSearchPointOnMapClick() {
    // console.log("SearchPlace:onCreateSearchPointOnMapClick");
    this.createPointOnMapActive = true;
    this.createPointOnMap.emit();
  }

  public onSearchHelpClick() {
    this.visibleSearchHelp = !this.visibleSearchHelp;
  }

  private clonePlace(mapPoint: MapPlaceL) {
    const mapPlace = {} as MapPlaceL;
    mapPlace.id = mapPoint.id;
    mapPlace.name = mapPoint.name;
    mapPlace.coordLon = mapPoint.coordLon;
    mapPlace.coordLat = mapPoint.coordLat;
    mapPlace.label = mapPoint.label;
    return mapPlace;
  }

  private async createUsageLog(actionType: EnumActionType, info: string) {
    const startedAt = new Date(Date.now());
    const referrer = document.referrer;
    const appVersion = environment.releaseVersion;
    const usageLog = {} as UsageLogMaps;
    usageLog.actionType = actionType;
    usageLog.actionInfo = info;
    usageLog.sessionId = this.sessionId;
    usageLog.userLanguage = this.languageCode;
    usageLog.referrer = referrer;
    usageLog.appVer = appVersion;
    usageLog.at = startedAt;
    const result = await this.globalService.addUsageLogMaps(usageLog);
    if (result.status != EnumGlobalStatusCode.Success) {
      const apiErr = createApiError(result, "addUsageLogMaps");
      this.showError.emit(apiErr);
    }

  }


}
