import { Component, OnInit, OnDestroy, Input, ElementRef, ViewChild, AfterViewInit, OnChanges, SimpleChanges } from "@angular/core";
import { getUserLanguage } from "src/app/utils/utils";

import { environment } from "src/environments/environment";

@Component({
  selector: "app-info-about-component",
  templateUrl: "./info-about.component.html",
  styleUrls: ["./info-about.component.scss"]
})
export class InfoAboutComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input() uiType: string;
  @Input() languageCode: string;

  public releaseDate: string = environment.releaseDate;
  public releaseVersion = environment.releaseVersion;

  @ViewChild("aboutId") aboutElement: ElementRef;

  constructor() { }

  public async ngOnInit() {
    // this.languageCode = "en"; // for testing other languages
    // console.log("About:ngOnInit-languageCode", this.languageCode);
  }
  public ngAfterViewInit(): void {
    this.aboutElement.nativeElement.scrollIntoView({ behavior: "smooth" });
  }

  public async ngOnChanges(changes: SimpleChanges) {
    console.log("About:onChanges-changes", changes);
  }

  public ngOnDestroy(): void {
  }



}
