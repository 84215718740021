import { NgModule, } from "@angular/core";
import { RouterModule, Routes, } from "@angular/router";
import { FormsModule, } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { CdkAccordionModule } from "@angular/cdk/accordion";


import { InfoAboutComponent } from "./info-about/info-about.component";
import { FooterBugsComponent } from "./footer-bugs/footer-bugs.component";
import { InfoFaqComponent } from "./info-faq/info-faq.component";
import { FooterNewsComponent } from "./footer-news/footer-news.component";
import { FooterPrivacyPolicyComponent } from "./info-privacypolicy/info-privacypolicy.component";
import { InfoTermsOfUseComponent } from "./info-termsofuse/info-termsofuse.component";
import { InfoImpressumComponent } from "./info-impressum/info-impressum.component";
import { MatButtonModule } from "@angular/material/button";
import { InfoOtherAppsComponent } from "./info-other-apps/info-other-apps.component";
import { MapsInfosComponent } from "./maps-infos/maps-infos.component";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { HttpLoaderFactory } from "../module-maps/maps.module";
import { HttpClient } from "@angular/common/http";
import { MatTabsModule } from "@angular/material/tabs";


// const routes: Routes = [
//   // { path: "", component: FooterAboutComponent, pathMatch: "full" },
//   { path: "about", component: FooterAboutComponent },
//   { path: "bugs", component: FooterBugsComponent },
//   { path: "faq", component: FooterFaqComponent },
//   { path: "impressum", component: FooterImpressumComponent },
//   { path: "news", component: FooterNewsComponent },
//   { path: "privacypolicy", component: FooterPrivacyPolicyComponent },
//   { path: "termsofuse", component: FooterTermsOfUseComponent },
// ];

@NgModule({
  declarations: [
    MapsInfosComponent,
    InfoAboutComponent,
    FooterBugsComponent,
    InfoFaqComponent,
    InfoImpressumComponent,
    FooterNewsComponent,
    FooterPrivacyPolicyComponent,
    InfoTermsOfUseComponent,
    InfoOtherAppsComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    // RouterModule.forChild(routes),
    TranslateModule.forRoot({
      defaultLanguage: "en",
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    CdkAccordionModule,
    MatButtonModule,
    MatTabsModule,
  ],
  providers: [
  ],
  exports: [
    MapsInfosComponent,
    InfoAboutComponent,
    FooterBugsComponent,
    InfoFaqComponent,
    InfoImpressumComponent,
    FooterNewsComponent,
    FooterPrivacyPolicyComponent,
    InfoTermsOfUseComponent,
    InfoOtherAppsComponent,
  ]
})
export class InfosModule {

}
