import { Component, OnInit, OnDestroy, ViewChild, LOCALE_ID, AfterViewInit, Input } from "@angular/core";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

import { getDeviceTypeName } from "src/app/utils/utils";
import { TranslateService } from "@ngx-translate/core";
import { EnumActionType, UsageLogMaps } from "src/dto.generated/api";
import { environment } from "src/environments/environment";
import { GlobalService } from "src/app/services/global.service";
import { Router } from "@angular/router";


@Component({
  selector: "app-start-page-component",
  templateUrl: "./start-page.component.html",
  styleUrls: ["./start-page.component.scss"]
})
export class StartPageComponent implements OnInit, AfterViewInit, OnDestroy {

  // device-type
  public destroyed = new Subject<void>();
  public currentDeviceType = "H-P";
  public uiType = "S";
  public windowWidth: number;
  public userLanguage: string;

  public hasAutoStart = false;
  public appVersion: string;
  public sessionId: string;
  private startedAt: Date;
  private referrer: string;


  constructor(
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private translate: TranslateService,
    private globalService: GlobalService,
  ) {
    // observe current dvice-type
    breakpointObserver.observe([
      Breakpoints.HandsetPortrait,
      Breakpoints.HandsetLandscape,
      Breakpoints.TabletPortrait,
      Breakpoints.TabletLandscape,
      Breakpoints.WebPortrait,
      Breakpoints.WebLandscape,
    ])
      .pipe(takeUntil(this.destroyed))
      .subscribe(result => {
        // console.log("Map1:query-result", result);
        for (const query of Object.keys(result.breakpoints)) {
          if (result.breakpoints[query]) {
            // console.log("Maps:query", query);
            this.currentDeviceType = getDeviceTypeName(query);
            // console.log("Maps:constructor-currentDeviceType", this.currentDeviceType);
            this.updateDeviceDependency();
          }
        }
      });

    // appVersion
    this.appVersion = environment.releaseVersion;
  }

  public async ngOnInit() {
    // console.log("StartPage:ngOnInit");
    // console.log("StartPage:ngOnInit-uiType", this.uiType);

    this.userLanguage = this.translate.getBrowserLang();
    // console.log("Maps:ngOnInit-userLanguage", this.userLanguage);
    // set language for i18n
    // this.userLanguage = "en";  // for tests
    this.translate.use(this.userLanguage);

    // autostart aus local-storage lesen
    this.loadLocalStorage();
    if (!this.hasAutoStart) {
      this.createUsageLog(EnumActionType.autostart, "autostart-no");
    }
    if (this.hasAutoStart) {
      this.createUsageLog(EnumActionType.autostart, "autostart-yes");
      // console.log("StartPage:ngOnInit-sessionId", this.sessionId);
      this.startApp();
    }
  }
  public ngAfterViewInit() {
    // console.log("MapX:ngAfterViewInit");
  }
  public ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }

  private updateDeviceDependency() {
    this.uiType = "L";
    if (this.currentDeviceType === "H-P") { this.uiType = "S"; }
    // console.log("StartPage:updateDeviceDependency-deviceType/uiType", this.currentDeviceType + "/" + this.uiType);
    this.windowWidth = window.innerWidth;
    // console.log("StartPage:updateDeviceDependency-screenWidth", this.windowWidth);
  }

  public onStartAppClick() {
    // console.log("StartPage:onStartAppClick");
    this.hasAutoStart = true;
    this.storeToLocalStorage();
    this.createUsageLog(EnumActionType.autostart, "start-app");
    this.startApp();
  }

  private startApp() {
    // window.location.href = "param?sessionId=" + this.sessionId;
    const url = "param?sessionId=" + this.sessionId;
    // console.log("StartPage:ngOnInit-sessionId", this.sessionId);
    this.router.navigateByUrl(url);
  }

  private loadLocalStorage() {
    const localStorage = window.localStorage;
    const item = localStorage.getItem("t4y-maps_auto-start");
    // console.log("StartPage:readLocalStorage:item", item);
    if (item) { this.hasAutoStart = true; }
  }

  private storeToLocalStorage() {
    const value = JSON.stringify(this.hasAutoStart);
    const localStorage = window.localStorage;
    localStorage.setItem("t4y-maps_auto-start", value);
  }

  private async createUsageLog(actionType: EnumActionType, info: string) {
    this.startedAt = new Date(Date.now());
    // referrer
    if (!this.referrer) {
      this.referrer = document.referrer;
      // console.log("Maps:ngOnInit-referrer", this.referrer);
    }
    if (!this.sessionId) {
      this.sessionId = this.globalService.getSessionId();
    }
    const usageLog = {} as UsageLogMaps;
    usageLog.actionType = actionType;
    usageLog.actionInfo = info;
    usageLog.sessionId = this.sessionId;
    usageLog.userLanguage = this.userLanguage;
    usageLog.referrer = this.referrer;
    usageLog.appVer = this.appVersion;
    usageLog.at = this.startedAt;
    const results = await this.globalService.addUsageLogMaps(usageLog);
  }

}
