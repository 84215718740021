<!-- new search-Dialog - small dev-->
<!-- <div>uiType:{{uiType}}</div> -->

<div #searchDlg *ngIf="visibleSearchDialog">


    <!-- header     -->
    <div *ngIf="selectType==='main'">
        <div style="display:flex; padding:0.5rem;">
            <img style="width:30px; height:30px;" src="./assets/icons/arrow-left-thin.svg" alt="symbol"
                title="{{'MAPS.GEN.BACK_ICON_T' | translate}}" (click)="onSearchBackClick()">
            <!-- title -->
            <span style="flex-grow:1; flex-shrink:1; flex-basis:content;"></span>
            <span style="margin-left:0.5rem; font-size:x-large;">{{'MAPS.SRCH.DLG.TITLE' | translate}}</span>
            <span style="flex-grow:1; flex-shrink:1; flex-basis:content;"></span>
            <!-- change-to-map -->
            <img style="cursor:pointer; margin-right:0.5rem;" width=36px src="./assets/icons/home-map-marker.svg"
                alt="symbol" title="{{'MAPS.GEN.CHANGE-TO-MAP_ICON_T' | translate}}" (click)="onSearchBackClick()">
        </div>
        <hr style="margin-top:0.2rem;margin-bottom:0.5rem;">
        <div style="display:flex; padding:0.5rem;">
            <input #searchInput type="text" class="input-search" enterkeyhint="search"
                placeholder="{{'MAPS.SRCH.DLG.SEARCH_INPUT_PLH' | translate}}" [(ngModel)]="searchParameter.text"
                (keyup)="onSearchSimpleTextKeyUp($event)">
            <img style="cursor:pointer; width:30px; height:30px;  margin-top:0.4rem; margin-left:0.5rem;"
                src="./assets/icons/help-circle-outline.svg" title="{{'MAPS.GEN.HELP_ICON_T' | translate}}"
                (click)="onSearchHelpClick()">
            <img style="cursor:pointer; width:30px; height:30px; margin-top:0.4rem;" src="./assets/icons/magnify.svg"
                title="{{'MAPS.SRCH.DLG.SEARCH_ICON_T' | translate}}" (click)="onSearchStringClick()">
            <img style="cursor:pointer; width:30px; height:30px; margin-top:0.4rem;" src="./assets/icons/close.svg"
                title="{{'MAPS.SRCH.DLG.CLEAR_ICON_T' | translate}}" (click)="onClearSearchStringClick()">
        </div>
    </div>
    <div *ngIf="selectType!=='main'" style="display:flex; margin-top:0.5rem;">
        <img style="cursor:pointer; width:30px; height:30px; margin-top:0.8rem;"
            src="./assets/icons/arrow-left-thin.svg" title="{{'MAPS.GEN.BACK_ICON_T' | translate}}"
            (click)="onSearchBackClick()">
        <input #searchInput type="text" class="input-search" enterkeyhint="search"
            placeholder="{{'MAPS.SRCH.DLG.SEARCH_INPUT_PLH' | translate}}" [(ngModel)]="searchParameter.text"
            (keyup)="onSearchSimpleTextKeyUp($event)">
        <img style="cursor:pointer; width:24px; height:24px;  margin-top:0.8rem;" src="./assets/icons/magnify.svg"
            title="{{'MAPS.SRCH.DLG.SEARCH_ICON_T' | translate}}" (click)="onSearchStringClick()">
        <img style="cursor:pointer; width:24px; height:24px;  margin-top:0.8rem;" src="./assets/icons/close.svg"
            title="{{'MAPS.SRCH.DLG.CLEAR_ICON_T' | translate}}" (click)="onClearSearchStringClick()">
    </div>

    <!-- search-errors -->
    <!-- ORS-Error -->
    <div *ngIf="orsPostErrorText" style="margin:0.5rem; color:crimson; max-width:360px">
        <hr style=" margin-top:0.3rem; margin-bottom:0.5rem;">
        <span style="margin-top:0.5rem; font-size:x-large;">{{'MAPS.SRCH.DLG.SEARCH-ERR_HTTP' | translate}}</span><br>
        <span style="margin-top:0.5rem; font-size:medium;">{{orsPostErrorText}}</span>
    </div>
    <!-- place-not-found-error -->
    <div *ngIf="placeNotFoundError" style="margin-top:0.5rem; margin-left:2.0rem; color:red; font-size:x-large;">
        <span *ngIf="!searchParameter.text">{{'MAPS.SRCH.DLG.SEARCH-ERR_NO-STRING' | translate}}</span>
        <span *ngIf="searchParameter.text">'{{searchParameter.text}}' {{'MAPS.SRCH.DLG.SEARCH-ERR_NOT-FOUND' |
            translate}}</span>
    </div>

    <!-- search icons -->
    <div *ngIf="selectType!=='main'" style="margin-left:1.0rem;">
        <!-- <img style="cursor:pointer; width:30px; height:30px; margin-left:1.0rem; margin-top:0.8rem;"
            src="./assets/icons/cog-outline.svg" title="search settings" (click)="onSearchSettingsClick()"> -->
        <img *ngIf="savedPlaces.length>0"
            style="cursor:pointer; width:30px; height:30px; margin-left:1.0rem; margin-top:0.8rem;"
            src="./assets/icons/star-outline.svg" title="{{'MAPS.SRCH.DLG.SEL-FROM-FAV_ICON_T' | translate}}"
            (click)="onSearchSavedPlacesClick()">
        <img style="cursor:pointer; width:30px; height:30px; margin-left:1.0rem; margin-top:0.8rem;"
            src="./assets/icons/help-circle-outline.svg" title="{{'MAPS.GEN.HELP_ICON_T' | translate}}"
            (click)="onSearchHelpClick()">
        <img style="cursor:pointer; width:30px; height:30px; margin-left:1.0rem; margin-top:0.8rem;"
            src="./assets/icons/map-marker-red.svg" title="{{'MAPS.SRCH.DLG.SEL-ON-MAP_ICON_T' | translate}}"
            (click)="onCreateSearchPointOnMapClick()">
        <span *ngIf="createPointOnMapActive" style="cursor:pointer;" (click)="onCreateSearchPointOnMapClick()">
            {{'MAPS.SRCH.DLG.SEL-ON-MAP_MSG' | translate}}</span>
    </div>
    <!-- <div style="margin-left:1.0rem;">
    </div> -->

    <!-- progress-spinner -->
    <div *ngIf="isVisibleProgressSpinner">
        <mat-progress-spinner [mode]="'indeterminate'" [diameter]="50" style="margin:auto;"></mat-progress-spinner>
    </div>

    <!-- search settings -->
    <div *ngIf="true"
        style="margin:0.5rem; border:3px solid cornflowerblue; border-radius:10px; padding:0.5rem; background:whitesmoke">
        <span style="font-size:large;">{{'MAPS.SRCH.DLG.OPTIONS_MSG' | translate}}</span><br>
        <!-- focus on current position -->
        <div *ngIf="currentLocation" style="margin-top:1.0rem;">
            <mat-slide-toggle color="primary" style="margin-left:0.5rem;"
                [(ngModel)]="searchParameter.focusCurrentPosition">
                {{'MAPS.SRCH.DLG.FOCUS-ON-CURRENT-POS_TOGGLE_TEXT' | translate}}
            </mat-slide-toggle>
        </div>
        <!-- search only in map extend -->
        <div style="margin-top:0.5rem;">
            <mat-slide-toggle color="primary" style="margin-left:0.5rem;" [(ngModel)]="searchParameter.onlyInMapExtent">
                {{'MAPS.SRCH.DLG.IN-MAP-EXTENT_TOGGLE_TEXT' | translate}}
            </mat-slide-toggle>
        </div>
    </div>

    <!-- select from saved places -->
    <div *ngIf="visibleSavedPlaces">
        <app-saved-place-selection-component [savedPlaces]="savedPlaces" (selectedPlace)="onSelectSavedPlace($event)">
        </app-saved-place-selection-component>
    </div>

    <!-- help -->
    <div *ngIf="visibleSearchHelp"
        style="border: 1px; border-radius: 10px; background-color: lemonchiffon; margin:0.5rem; padding:0.5rem;">
        <span style="font-size: large;">{{'MAPS.SRCH.DLG.HELP_MSG' | translate}}</span>
        <hr>
        <div style="margin-top:0.5rem; margin-left:0.5rem; margin-bottom:0;">
            <img style="width:20px; height:20px;" src="./assets/icons/magnify.svg">
            <span style="margin-left:0.5rem;">{{'MAPS.SRCH.DLG.SEARCH_ICON_T' | translate}}</span>
        </div>
        <div style="margin-top:0.5rem; margin-left:0.5rem; margin-bottom:0;">
            <img style="width:20px; height:20px;" src="./assets/icons/close.svg">
            <span style="margin-left:0.5rem;">{{'MAPS.SRCH.DLG.CLEAR_ICON_T' | translate}}</span>
        </div>
        <!-- <div style="margin-top:0.5rem; margin-left:0.5rem; margin-bottom:0;">
            <img style="width:20px; height:20px;" src="./assets/icons/star-plus-outline.svg">
            <span style="margin-left:0.5rem;">{{'MAPS.SRCH.DLG.SEL-FROM-FAV_ICON_T' | translate}}</span>
        </div>
        <div style="margin-top:0.5rem; margin-left:0.5rem; margin-bottom:0;">
            <img style="width:20px; height:20px;" src="./assets/icons/map-marker-red.svg">
            <span style="margin-left:0.5rem;">{{'MAPS.SRCH.DLG.SEL-ON-MAP_ICON_T' | translate}}</span>
        </div> -->
        <!-- search-info -->
        <hr>
        <div *ngIf="true" style="padding-left:0.5rem;">
            <ng-container *ngIf="languageCode === 'de'">
                <h4 style="margin-bottom:0.5rem;">Wie geht die Suche?</h4>
                <span style="font-size:large;">Suchbegriff:</span>
                <span style="font-size:medium; margin-left:0.5rem;">
                    <i>Adresse</i> oder <i>Objekt</i>, <i>Optionen</i></span><br>
                <div style="margin-top:0.5rem; font-size:small; margin-left:1.0rem;">
                    <span style="font-size:medium;"><i>Adresse:</i></span>
                    <span> Straße Hausnummer</span><br>
                    <span style="margin-left:1.0rem;">z.B. <b>Stephansplatz 1</b></span><br><br>
                    <span style="font-size:medium;"><i>Objekt:</i></span>
                    <span> Objektart oder Objektname</span><br>
                    <span style="margin-left:1.0rem;">z.B. <b>Atelier</b> oder <b>Atelier Wink</b></span><br><br>
                    <span style="font-size:medium;"><i>Optionen:</i></span>
                    <span style="margin-left:0.5rem;">Ort, Region, Land</span><br>
                    <div style="margin-top:0.5rem; margin-left:1.0rem;">
                        <span>Ort: Stadt oder Gemeinde oder Stadtbezirk</span><br>
                        <span style="margin-left:1.0rem;">z.B. <b>Salzburg</b></span><br><br>
                        <span>Region: Bundesland oder Bezirk</span><br>
                        <span style="margin-left:3.0rem;">(Lokaler Name oder Kürzel)</span><br>
                        <span style="margin-left:1.0rem;">z.B. <b>Steiermark</b> oder <b>ST</b></span><br><br>
                        <span>Land(Staat): Ländername (englisch) oder</span><br>
                        <span style="margin-left:1.0rem;"> Alpha-2 or Alpha-3
                            <a href="https://en.wikipedia.org/wiki/ISO_3166-1" target="_blank" rel="noopener">ISO-3166
                                Ländercode</a></span><br>
                        <span style="margin-left:1.0rem;">z.B. <b>Austria</b></span><br>
                        <span style="margin-left:1.0rem;">z.B. <b>AT</b> oder <b>AUT</b> für Österreich)</span><br><br>
                    </div>
                </div>
                <div style="margin-top:0.5rem; font-size:small;">
                    <span style="font-size:large">Beispiele:</span><br>
                    <span style="margin-left:1.0rem;"><b>Stephansplatz 1, Wien, Austria</b></span><br>
                    <span style="margin-left:1.0rem;"><b>Grabenweg 6, ST, AUT</b></span><br>
                    <span style="margin-left:1.0rem;"><b>Atelier Wink, AT</b></span><br>
                    <span style="margin-left:1.0rem;"><b>Salzburg, AUT</b></span><br>
                    <span style="margin-left:1.0rem;"><b>Schloßberg, AUT</b></span><br>
                </div>
            </ng-container>

            <ng-container *ngIf="languageCode !== 'de'">
                <h4 style="margin-bottom:0.5rem;">How to search?</h4>
                <span style="font-size:large;">Search string:</span><br>
                <span style="font-size:medium; margin-left:0.5rem;">
                    <i>Address</i> or <i>object</i>, options</span><br>
                <div style="margin-top:0.5rem; font-size:small; margin-left:1.0rem;">
                    <span style="font-size:medium;"><i>Address:</i></span>
                    <span> Street housenumber</span><br>
                    <span style="margin-left:1.0rem;">f.e. <b>Stephansplatz 1</b></span><br><br>
                    <span style="font-size:medium;"><i>Object:</i></span>
                    <span> Object name or object type</span><br>
                    <span style="margin-left:1.0rem;">f.e. <b>Atelier Wink</b> or <b>Atelier</b></span><br><br>
                    <span style="font-size:medium;"><i>Options:</i></span>
                    <span style="margin-left:0.5rem;">Locality, region, country</span>
                    <div style="margin-top:0.5rem; font-size:small; padding-left:1.0rem;">
                        <span>Locality: City or comunity or neighborhood</span><br>
                        <span style="margin-left:1.0rem;">f.e. <b>Salzburg</b></span><br><br>
                        <span>Region: State or county</span><br>
                        <span style="margin-left:3.0rem;">Local name or abbreviation</span><br>
                        <span style="margin-left:1.0rem;">f.e. <b>Steiermark</b> or <b>ST</b></span><br><br>
                        <span>Country: Country name (english) or</span><br>
                        <span style="margin-left:3.5rem;"> Alpha-2 or Alpha-3</span><br>
                        <span style="margin-left:3.5rem;"><a href="https://en.wikipedia.org/wiki/ISO_3166-1"
                                target="_blank" rel="noopener">ISO-3166 Country code</a></span><br>
                        <span style="margin-left:1.0rem;">f.e. <b>Austria</b></span><br>
                        <span style="margin-left:1.0rem;">f.e. <b>AT</b> oder <b>AUT</b> for Austria)</span><br><br>
                    </div>
                </div>
                <div style="margin-top:0.5rem; font-size: small;">
                    <span style="font-size:large">Examples:</span><br>
                    <span style="margin-left:1.0rem;"><b>Stephansplatz 1, Wien, Austria</b></span><br>
                    <span style="margin-left:1.0rem;"><b>Grabenweg 6, ST, AUT</b></span><br>
                    <span style="margin-left:1.0rem;"><b>Atelier Wink, AT</b></span><br>
                    <span style="margin-left:1.0rem;"><b>Salzburg, AUT</b></span><br>
                    <span style="margin-left:1.0rem;"><b>Schloßberg, AUT</b></span><br>
                </div>
            </ng-container>
        </div>
    </div>

    <!-- search-results -->
    <div *ngIf="foundPlaces && foundPlaces.length  && selectedPlace" style="margin-top:1.0rem; font-size:x-large;">
        <!-- <div>id2:{{selectedPlace.id}}</div> -->
        <div *ngFor=" let xPlace of foundPlaces" [class.item-selected]="xPlace.id===selectedPlace.id"
            style="display:flex; cursor:pointer; margin:0.5rem; background-color: lemonchiffon; padding:0.5rem; font-size:medium; border-radius:12px;">
            <div (click)="onSelectSearchResult(xPlace)">
                <span>{{xPlace.name}}</span>
                <span *ngIf="xPlace.distance" style="margin-left:0.5rem">({{xPlace.distance | number:
                    '1.1-1'}}km)</span><br>
                <span style="margin-left:1.0rem;font-size:smaller;">{{xPlace.label}}</span>
            </div>
            <div style="flex-shrink:10; flex-grow:10;"> </div>
            <div>
                <button *ngIf="selectType=='main'" mat-icon-button style="margin-top:0;"
                    (click)="onRouteToSearchResult(xPlace)">
                    <img style="height:30px" src="./assets/icons/directions.svg"
                        title="{{'MAPS.SRCH.DLG.RPL_ICON_T' | translate}}">
                </button>
            </div>
        </div>
    </div>

</div>